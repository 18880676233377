import { JSX } from 'react';

import RioglyphIcon from '../wrapper/RioglyphIcon';
import { EmptyDataIconWithTooltip } from '../tables/elements/EmptyDataIcon';
import { DurationComponent } from '../formatting/TimeFormatting';

const BadgeTimerChart = (props: BadgeTimerChartProps): JSX.Element => {
    const { color, icon, minutesLeft, minutesRight } = props;
    return (
        <div className={'BadgeTimerChart position-relative'}>
            <div className={'position-absolute padding-1 bg-light width-100pct margin-top--1'} style={{ top: '50%' }} />
            <div className={'position-relative display-flex justify-content-between'}>
                <span className={'padding-right-5 bg-white'}>
                    {minutesLeft ? (
                        <DurationComponent minutes={minutesLeft} displayColor={color} displaySize={'16'} />
                    ) : (
                        <EmptyDataIconWithTooltip />
                    )}
                </span>
                <RioglyphIcon
                    className={'padding-left-5 padding-right-5 bg-white'}
                    type={icon}
                    color={'light'}
                    size={'20'}
                />
                <span className={'padding-left-5 bg-white'}>
                    {minutesRight ? (
                        <DurationComponent minutes={minutesRight} displayColor={color} displaySize={'16'} />
                    ) : (
                        <EmptyDataIconWithTooltip />
                    )}
                </span>
            </div>
        </div>
    );
};

interface BadgeTimerChartProps {
    color: string;
    icon: string;
    minutesLeft?: number;
    minutesRight?: number;
}

export default BadgeTimerChart;
