import { JSX } from 'react';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import { connect } from 'react-redux';
import { getTableViewMode } from '../../realtime/header/tablesettings/redux/tablesettings.redux';
import { TableViewMode } from '../../realtime/header/tablesettings/redux/types';
import { StateChangesTableBodyContainer } from './StateChangesTableBody.container';
import { StateChangesTableColGroupsContainer } from '../header/TableColGroups.container';
import { StateChangesTableHeadContainer } from './StateChangesTableHead.container';
import { LoadMoreStateChangesButtonContainer } from '../../realtime/header/tablesettings/LoadMoreButton.container';
import { StateChangesDetailsDialogContainer } from '../../detailsdialog/StateChangesDetailsDialog.container';
import { State } from '../../../reducers';

interface StateChangesTableProps {
    viewType: TableViewMode;
}

const StateChangesTable = (props: StateChangesTableProps): JSX.Element => {
    const { viewType } = props;

    const tableClassNames = [
        'table',
        'table-sticky',
        'table-layout-fixed',
        'table-column-overflow-hidden',
        'table-bordered',
        'table-head-filled',
        viewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD && 'table-cards table-single-card',
        viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS && 'table-cards table-multi-cards',
    ].join(' ');

    return (
        <div data-testid={'ActivitiesTable'}>
            <StateChangesDetailsDialogContainer />
            <div>
                <table className={tableClassNames}>
                    <StateChangesTableColGroupsContainer />
                    <StateChangesTableHeadContainer />
                    <StateChangesTableBodyContainer />
                </table>
            </div>
            <LoadMoreStateChangesButtonContainer />
        </div>
    );
};

const mapStateToProps = (state: State): StateChangesTableProps => ({
    viewType: getTableViewMode(state),
});

export const StateChangesTableContainer = connect(mapStateToProps)(StateChangesTable);
