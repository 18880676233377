import { useState, type PropsWithChildren, JSX } from 'react';
import BottomSheet from '@rio-cloud/rio-uikit/BottomSheet';
import useTimeout from '@rio-cloud/rio-uikit/useTimeout';
import useLocalStorage from '@rio-cloud/rio-uikit/useLocalStorage';
import useEffectOnce from '@rio-cloud/rio-uikit/useEffectOnce';
import noop from 'lodash/fp/noop';

const DEFAULT_SHOW_AFTER = 0; // immediately after mount
const DEFAULT_HIDE_AFTER = 3_600_000; // after 1 hour

// Features:
// [x] show delayed
// [x] option to hide automatically after x
// [x] store closed user state in localStorage
// [x] don't show when user has clicked to hide - per feature
// [x] Cleanup localStorage feature
// [x] hide on click inside of content (button or link, etc.) - control from outside
// [-] GA tracking of user hide click - should be done outside

type TimedBottomSheetProps = {
    /**
     * The `dismissed` flag can be used to tell this component that the user has clicked on the content
     * of the component like a button or a link. In this case, the bottom sheet will store a flag in the
     * localStorage to hide the bottom sheet for the next time and it will close the sheet right away.
     */
    dismissed?: boolean;
    /**
     * The `featureName` prop is used to name the localStorage flag that is used to control the visibility.
     * It should be unique to avoid conflicts with other timed bottom sheets.
     */
    featureName: string;
    /**
     * Defines the time in milliseconds when the bottom sheet shall be shown. Default value is 0 to show it right away.
     */
    showAfter?: number;
    /**
     * Defines the time in milliseconds when the bottom sheet hides itself automatically. Default value is 3_600_000
     * to hide it after 1 hour. In this case, the localStorage flag is not set and the bottom sheet will be shown
     * on the next page load.
     */
    hideAfter?: number;
    /**
     * Optional width of the bottom sheet. Alternatively, you can set a `max-width-xxx` via className instead.
     */
    width?: number;
    /**
     * Flag to allow to remove the localStorage flag again once the component is not needed anymore.
     */
    cleanupLocalStorage?: boolean;
    /**
     * Callback function that gets triggered when the user closed the bottom sheet with the close button.
     * @returns void
     */
    onClose?: () => void;
    /**
     * Optional className to be set on the component. Use this to define a max-width value.
     */
    className?: string;
};

const sanitizeFeatureName = (value: string) => `${value.charAt(0).toUpperCase()}${value.slice(1)}`.replaceAll(' ', '');

/**
 * A wrapper component for the BottomSheet that allows to control it's visibility via timers and to use
 * the localStorage to save user interaction.
 *
 * Don't forget to cleanup the localStorage for the user when removing a feature
 * by setting "cleanupLocalStorage" to "true" and deploy it like this.
 * The cleanup will remove the localStorage flag on mount and will not show the bottom sheet.
 *
 * @example
 const [isDismissed, setIsDismissed] = useState(false);

 const handleClose = () => { // If needed trigger Google Analytics here };

 return (
 <TimedBottomSheet
 dismissed={isDismissed}
 featureName='dummyBottomSheet'
 showAfter={1_000}
 hideAfter={15_000}
 className='max-width-500'
 onClose={handleClose}
 >
 <div className='display-flex align-items-center gap-20'>
 Lorem ipsum dolor sit amet
 <Button bsStyle={Button.PRIMARY} onClick={() => setIsDismissed(true)}>
 Got it
 </Button>
 </div>
 </TimedBottomSheet>
 );
 */
export const TimedBottomSheet = (props: PropsWithChildren<TimedBottomSheetProps>): JSX.Element => {
    const {
        dismissed = false,
        featureName,
        showAfter = DEFAULT_SHOW_AFTER,
        hideAfter = DEFAULT_HIDE_AFTER,
        width,
        cleanupLocalStorage = false,
        onClose = noop,
        className,
        children,
    } = props;

    const [showBottomSheet, setShowBottomSheet] = useState(false);

    const [isHiddenByUser, setIsHiddenByUser, removeIsHiddenFlag] = useLocalStorage(
        `drivingtime.hide${sanitizeFeatureName(featureName)}`,
        false
    );

    // In case the visibility is controlled by the outside, means the user clicked on the
    // content of the bottom sheet, close the sheet and set the localStorage flag for not
    // showing it again
    const [prevDismissed, setPrevDismissed] = useState(dismissed);
    if (dismissed && dismissed !== prevDismissed) {
        setPrevDismissed(dismissed);
        setIsHiddenByUser(true);
        setShowBottomSheet(false);
    }

    // Show the bottom sheet automatically after a given amount of time
    useTimeout(() => {
        if (!dismissed && !isHiddenByUser && !cleanupLocalStorage) {
            setShowBottomSheet(true);
        }
    }, showAfter);

    // Hide the bottom sheet automatically after a given amount of time
    useTimeout(() => setShowBottomSheet(false), showAfter + hideAfter);

    // Cleanup functionality. The cleanup will remove the localStorage flag on mount
    // and will not show the bottom sheet.
    useEffectOnce(() => {
        if (cleanupLocalStorage) {
            removeIsHiddenFlag();
        }
    });

    const handleCloseBottomSheet = () => {
        setShowBottomSheet(false);
        setIsHiddenByUser(true);
        onClose();
    };

    return (
        <div data-testid={'timed-bottom-sheet'}>
            <BottomSheet
                show={showBottomSheet}
                width={width}
                detach
                onClose={handleCloseBottomSheet}
                className={className}
                bodyClassName="padding-25 margin-right-25"
            >
                {children}
            </BottomSheet>
        </div>
    );
};
