import { JSX } from 'react';
import { FormattedMessage } from 'react-intl';

import FormattedTimeStamp from './FormattedTimeStamp.js';
import RioglyphIcon from '../wrapper/RioglyphIcon';

import { getTimeStampType, TimeStampCategoryConfig } from '../enums/TimeStampCategory';

import { TooltipWrapper } from '../onboarding/TooltipWrapper';
import { DurationComponent } from '../formatting/TimeFormatting';
import moment, { Moment } from 'moment';
import { ShiftTimes } from './types';
import { EmptyDataIconWithTooltip, EmptyDataMultiManning } from '../tables/elements/EmptyDataIcon';

const FULL_WIDTH = 100;
const HALF_WIDTH = 50;
const TWO = 2;

const GRID_WIDTH_1 = 70;
const GRID_WIDTH_2 = FULL_WIDTH - GRID_WIDTH_1;

const BADGE_GRID_FLOAT_BORDER = 8;

const FULL_HEIGHT = 50;
const TOP_HEIGHT = 1;
const MIDDLE_HEIGHT = 30;
const BORDER_POSITION = TOP_HEIGHT + MIDDLE_HEIGHT;

const ONE_MINUTE_MILLISECONDS = 60 * 1000;
const ONE_HOUR_MINUTES = 60;
const FIFTEEN_HOURS_MILLISECONDS = 15 * ONE_HOUR_MINUTES * ONE_MINUTE_MILLISECONDS;
const TWO_HOURS_MILLISECONDS = 2 * ONE_HOUR_MINUTES * ONE_MINUTE_MILLISECONDS;

const takenOver13HoursPercentForGrid = (GRID_WIDTH_1 * TWO_HOURS_MILLISECONDS) / FIFTEEN_HOURS_MILLISECONDS;

const calculateRemainingWidth = (isMinimumDailyRestMax: boolean, remainingWidthRaw: number) => {
    if (isMinimumDailyRestMax) {
        return 0;
    }

    const calculatedRaw = remainingWidthRaw - takenOver13HoursPercentForGrid;
    return calculatedRaw > 0 ? calculatedRaw : 0;
};

const getGrid1Width = (width: number) => {
    if (width < 0) {
        return 0;
    }

    return width > GRID_WIDTH_1 ? GRID_WIDTH_1 : width;
};

interface ShiftChartProps {
    timeStamp: Moment;
    minimumDailyRestMinimum: number | undefined;
    receivedTimestamp: Moment | undefined;
    shiftTimes: ShiftTimes | undefined;
    multiManning: boolean;
}

interface ColorConfig {
    shiftEnd: string;
    taken: string;
    dailyRest: string;
    takenBadge: string | undefined;
}

interface ErrorConfig {
    isMinimumDailyRestMax: boolean;
    isShiftMaxExceeded: boolean;
}

// eslint-disable-next-line max-lines-per-function
const ShiftChart = (props: ShiftChartProps): JSX.Element => {
    // eslint-disable-line max-statements, complexity
    const { timeStamp, minimumDailyRestMinimum, receivedTimestamp, shiftTimes, multiManning } = props;

    const widths = {
        takenWidth: 0,
        remainingWidth: GRID_WIDTH_1 - takenOver13HoursPercentForGrid,
    };

    const badges = {
        left: 50,
        right: 50,
    };

    let times: ShiftTimes | undefined = !multiManning && shiftTimes ? shiftTimes : undefined;

    const colors: ColorConfig = {
        shiftEnd: 'text-color-darkest',
        taken: 'progress-bar-success',
        dailyRest: 'gray',
        takenBadge: undefined,
    };

    const errors: ErrorConfig = { isMinimumDailyRestMax: false, isShiftMaxExceeded: false };

    if (shiftTimes && !multiManning) {
        // eslint-disable-next-line react/prop-types
        const { shiftStart, shiftEnd, shiftTime, shiftMax, shiftExceeded, timeLeft, dailyRestWarning } = shiftTimes;

        const takenPercent = (FULL_WIDTH * shiftTime) / shiftMax;
        const remainingPercent = FULL_WIDTH - takenPercent;
        const remainingWidthRaw = (remainingPercent * GRID_WIDTH_1) / FULL_WIDTH;
        const takenWidth = GRID_WIDTH_1 - remainingWidthRaw;

        const remainingWidth = calculateRemainingWidth(dailyRestWarning, remainingWidthRaw);

        widths.takenWidth = getGrid1Width(takenWidth);
        widths.remainingWidth = getGrid1Width(remainingWidth);

        if (dailyRestWarning) {
            widths.remainingWidth = GRID_WIDTH_1 - widths.takenWidth;
        }

        const halfTakenPercent = takenPercent / TWO;
        const halfRemainingPercent = remainingPercent / TWO;

        badges.left = halfTakenPercent < HALF_WIDTH ? halfTakenPercent : HALF_WIDTH;
        badges.right = halfRemainingPercent < HALF_WIDTH ? halfRemainingPercent : HALF_WIDTH;

        if (times) {
            times = {
                ...times,
                shiftStart: moment(shiftStart),
                shiftEnd: moment(shiftEnd),
                timeLeft: timeLeft > 0 ? timeLeft : 0,
                shiftTime,
            };
        }

        if (shiftExceeded) {
            colors.shiftEnd = 'text-danger';
        }

        const timeStampType = getTimeStampType(moment(timeStamp), moment(receivedTimestamp));
        const timeStampColor = TimeStampCategoryConfig[timeStampType].color;

        if (timeStampColor === 'color-dark') {
            colors.taken = `bg-${shiftExceeded ? 'danger' : 'dark'}`;
            colors.takenBadge = `bg-${shiftExceeded ? 'danger' : 'dark'}`;
        } else {
            colors.taken = `progress-bar-${shiftExceeded ? 'danger' : timeStampColor}`;
            colors.takenBadge = `bg-${shiftExceeded ? 'danger' : timeStampColor}`;
        }

        colors.dailyRest = dailyRestWarning ? 'warning' : 'gray';

        errors.isMinimumDailyRestMax = dailyRestWarning;
        errors.isShiftMaxExceeded = shiftExceeded;
    } else if (timeStamp && receivedTimestamp) {
        const timeStampType = getTimeStampType(moment(timeStamp), moment(receivedTimestamp));
        const timeStampColor = TimeStampCategoryConfig[timeStampType].color;

        if (timeStampColor === 'color-dark') {
            colors.taken = 'bg-dark';
            colors.takenBadge = 'bg-dark';
        } else {
            colors.taken = `progress-bar-${timeStampColor}`;
            colors.takenBadge = `bg-${timeStampColor}`;
        }
    }

    const emptyDataIconWithPossibleManningTooltip = multiManning ? (
        <EmptyDataMultiManning />
    ) : (
        <EmptyDataIconWithTooltip />
    );
    return (
        <div className={'ShiftChart form-group'}>
            <div
                className={'display-inline-block text-color-darkest margin-top-5 margin-bottom-5 form-group'}
                style={{ width: `${GRID_WIDTH_1}%` }}
            >
                <label className={'margin-bottom-0'}>
                    <FormattedMessage id={'intl-msg:timed.common.shiftBegin'} />
                </label>
                <div className={'text-size-12'}>
                    <strong>{times ? <FormattedTimeStamp date={times.shiftStart} /> : '-'}</strong>
                </div>
            </div>
            <div
                className={`display-inline-block ${colors.shiftEnd} margin-top-5 margin-bottom-5 form-group`}
                style={{ width: `${GRID_WIDTH_2}%` }}
            >
                <label className={'margin-bottom-0'}>
                    <FormattedMessage id={'intl-msg:timed.common.shiftEnd'} />
                </label>
                <div className={'text-size-12'}>
                    <strong>{times ? <FormattedTimeStamp date={times.shiftEnd} /> : '-'}</strong>
                </div>
            </div>
            <div className={'progress StatusBar margin-0 position-relative'} style={{ height: `${FULL_HEIGHT}px` }}>
                <div className={'progress margin-0 flex-1-0 position-relative'} style={{ height: `${FULL_HEIGHT}px` }}>
                    <div
                        className={`progress-bar ${colors.taken} progress-divider`}
                        style={{ width: `${widths.takenWidth}%` }}
                    />
                    <div
                        className={'progress-bar progress-bar-lightest position-absolute progress-divider text-size-16'}
                        style={{
                            width: `${GRID_WIDTH_1}%`,
                            top: `${TOP_HEIGHT}px`,
                            height: `${MIDDLE_HEIGHT}px`,
                            lineHeight: `${MIDDLE_HEIGHT}px`,
                        }}
                    >
                        <div className={'position-relative'}>
                            {times ? (
                                <div
                                    className={'position-absolute height-100pct'}
                                    style={{ left: badges.left >= BADGE_GRID_FLOAT_BORDER ? `${badges.left}%` : '1%' }}
                                >
                                    <TooltipWrapper tooltipId={'intl-msg:timed.common.shiftTime'}>
                                        <span
                                            className={`position-relative badge ${colors.takenBadge}`}
                                            style={{ left: badges.left >= BADGE_GRID_FLOAT_BORDER ? '-50%' : '' }}
                                        >
                                            <DurationComponent
                                                minutes={times ? times.shiftTime : 0}
                                                displayColor={'white'}
                                            />
                                        </span>
                                    </TooltipWrapper>
                                </div>
                            ) : (
                                emptyDataIconWithPossibleManningTooltip
                            )}

                            <div
                                className={'position-absolute height-100pct'}
                                style={{
                                    right: badges.right >= BADGE_GRID_FLOAT_BORDER ? `${badges.right}%` : '1%',
                                }}
                            >
                                {times && !errors.isShiftMaxExceeded ? (
                                    <TooltipWrapper tooltipId={'intl-msg:timed.common.remainingShiftTime'}>
                                        <span
                                            className={
                                                'position-relative badge bg-light border-color-light' +
                                                ' text-color-dark'
                                            }
                                            style={{ right: badges.right >= BADGE_GRID_FLOAT_BORDER ? '-50%' : '' }}
                                        >
                                            <DurationComponent minutes={times.timeLeft} displayColor={'dark'} />
                                        </span>
                                    </TooltipWrapper>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className={'progress-bar bg-white position-absolute progress-divider'}
                        style={{ width: `${GRID_WIDTH_1}%`, top: `${BORDER_POSITION}px`, height: `${TOP_HEIGHT}px` }}
                    />
                    <div
                        className={'progress-bar progress-bar-light progress-divider'}
                        style={{ width: `${widths.remainingWidth}%` }}
                    />
                    <div
                        className={'progress-bar progress-bar-light progress-divider'}
                        style={{ width: errors.isMinimumDailyRestMax ? '0%' : `${takenOver13HoursPercentForGrid}%` }}
                    />

                    <div
                        className={'progress-bar progress-bar-gray progress-divider position-absolute'}
                        style={{ width: `${GRID_WIDTH_2}%`, right: 0 }}
                    >
                        <div
                            className={'progress-bar progress-bar-lightest position-absolute text-size-16 width-100pct'}
                            style={{
                                top: `${TOP_HEIGHT}px`,
                                height: `${MIDDLE_HEIGHT}px`,
                                lineHeight: `${MIDDLE_HEIGHT}px`,
                            }}
                        >
                            {minimumDailyRestMinimum ? (
                                <TooltipWrapper tooltipId={'intl-msg:timed.sidebar.minimumDailyRestTime'}>
                                    <span className={`badge bg-${colors.dailyRest} border-color-${colors.dailyRest}`}>
                                        <DurationComponent minutes={minimumDailyRestMinimum} displayColor={'white'} />
                                    </span>
                                </TooltipWrapper>
                            ) : (
                                emptyDataIconWithPossibleManningTooltip
                            )}
                        </div>
                        <div
                            className={'progress-bar width-100pct bg-white position-absolute progress-divider'}
                            style={{ top: `${BORDER_POSITION}px`, height: `${TOP_HEIGHT}px` }}
                        />
                    </div>
                </div>
                <div
                    className={'width-100pct position-absolute bg-light'}
                    style={{
                        top: '0',
                        height: `${TOP_HEIGHT}px`,
                    }}
                />
            </div>
            <div className={'display-flex form-group'}>
                <div className={'display-inline-block text-color-gray'} style={{ width: `${GRID_WIDTH_1}%` }}>
                    <div className={'position-relative'}>
                        <div className={'position-absolute'} style={{ left: 0 }}>
                            <span>{'0 h'}</span>
                        </div>
                        <div className={'position-absolute'} style={{ left: '50%' }}>
                            <span className={'position-relative'} style={{ left: '-50%' }}>
                                <RioglyphIcon type={'status-driving'} />
                                <RioglyphIcon type={'status-resting'} />
                                <RioglyphIcon type={'status-working'} />
                                <RioglyphIcon type={'status-available'} />
                            </span>
                        </div>
                        <div className={'position-absolute'} style={{ right: `${takenOver13HoursPercentForGrid}%` }}>
                            <span className={'position-relative'}>{errors.isMinimumDailyRestMax ? '' : '13 h'}</span>
                        </div>
                    </div>
                </div>
                <div className={'display-inline-block text-color-gray'} style={{ width: `${GRID_WIDTH_2}%` }}>
                    <div className={'position-relative'}>
                        <div className={'position-absolute'} style={{ left: 0 }}>
                            <span>{errors.isMinimumDailyRestMax ? '13 h' : '15 h'}</span>
                        </div>
                        <div className={'position-absolute'} style={{ left: '50%' }}>
                            <span className={'position-relative'} style={{ left: '-50%' }}>
                                <RioglyphIcon type={'status-resting'} />
                            </span>
                        </div>
                        <div className={'position-absolute'} style={{ right: 0 }}>
                            <span>{'24 h'}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShiftChart;
