import { JSX } from 'react';
import { DrivingTimesDisplayItem } from '../../tables/drivingtimes/types';

import { FormattedMessage } from 'react-intl';
import { LiveClock } from '../../realtime/header/LiveClock';
import { WorkingTimeDetailsView } from './WorkingTimeDetailsView';
import { CompliantDddInfoBoxContainer } from './workingtimes/CompliantDddInfoBox.container';
import { connect } from 'react-redux';
import { State } from '../../../reducers';
import { FunctionProperties, NonFunctionProperties } from '../../../utils/util';
import { getShowOutdatedData, showWorkingTimesInfoBox, widgetActions } from '../redux/widget.redux';
import { Dispatch } from 'redux';
import { GeneralInfoBox } from './workingtimes/GeneralInfoBox.container';
import { ShowOutdatedDataButton } from './ShowOutdatedDataButton';
import moment, { Moment } from 'moment/moment';
import { TableColumn } from '../../enums/TableColumn';

interface WorkingTimesViewProps {
    displayItem: DrivingTimesDisplayItem;
}

interface WorkingTimesViewStateProps {
    shouldShowInfoBox: boolean;
    showInfoBox: () => void;
    hideInfoBox: () => void;
    shouldShowOutdatedData: boolean;
    showOutdatedData: () => void;
}

interface WorkingTimesHeaderProps {
    displayItem: DrivingTimesDisplayItem;
    shouldShowInfoBox: boolean;
    showInfoBox: () => void;
    hideInfoBox: () => void;
}

const infoButton = (showInfoBox: () => void) => (
    <span
        role="button"
        aria-label={'info-button'}
        className={'rioglyph rioglyph-info-sign text-color-info cursor-pointer'}
        onClick={showInfoBox}
    />
);

const WorkingTimesHeaderComponent = (props: WorkingTimesHeaderProps): JSX.Element => {
    const { displayItem, shouldShowInfoBox, showInfoBox } = props;
    return (
        <div className={'display-flex justify-content-between padding-bottom-5'}>
            <div>
                <span className={'rioglyph rioglyph-status-available text-color-status-available padding-right-3'} />
                <span className={'rioglyph rioglyph-status-driving text-color-status-driving padding-right-3'} />
                <span className={'rioglyph rioglyph-status-working text-color-status-working padding-right-3'} />
                <FormattedMessage id={'intl-msg:timed.common.workingTimes.label'} />
            </div>
            <div data-testid={'working-times-latest-timestamp'}>
                <LiveClock date={displayItem[TableColumn.WORKING_TIMES_TIMESTAMP]} />{' '}
                {!shouldShowInfoBox && infoButton(showInfoBox)}
            </div>
        </div>
    );
};

const isDataOutdated = (timestamp?: Moment): boolean => {
    return moment().diff(timestamp, 'minutes') > 5;
};

const WorkingTimesView = (props: WorkingTimesViewProps & WorkingTimesViewStateProps): JSX.Element => {
    const { displayItem, shouldShowInfoBox, shouldShowOutdatedData, showInfoBox, hideInfoBox, showOutdatedData } =
        props;

    const renderHeader = () => (
        <WorkingTimesHeaderComponent
            displayItem={displayItem}
            shouldShowInfoBox={shouldShowInfoBox}
            showInfoBox={showInfoBox}
            hideInfoBox={hideInfoBox}
        />
    );

    // Paddings/margins are not part of the scroll-overflow.
    // Required to ensure container is always visible in the scroll window.
    const renderFooter = () => <div className="height-20" />;

    if (
        displayItem[TableColumn.WORKING_TIMES_TIMESTAMP] &&
        isDataOutdated(displayItem[TableColumn.WORKING_TIMES_TIMESTAMP]) &&
        !shouldShowOutdatedData
    ) {
        return (
            <>
                {renderHeader()}
                <div>
                    <div className="border rounded text-center padding-top-20 padding-bottom-20">
                        <ShowOutdatedDataButton onClick={showOutdatedData} />
                    </div>
                </div>
                {renderFooter()}
            </>
        );
    }

    return (
        <div data-testid={'working-times-container-view'}>
            {renderHeader()}
            <WorkingTimeDetailsView displayItem={displayItem} />
            <CompliantDddInfoBoxContainer displayItem={displayItem} />
            <GeneralInfoBox hideInfoBox={hideInfoBox} shouldShowInfoBox={shouldShowInfoBox} />
            {renderFooter()}
        </div>
    );
};
const mapStateToProps = (state: State): NonFunctionProperties<WorkingTimesViewStateProps> => ({
    shouldShowInfoBox: showWorkingTimesInfoBox(state),
    shouldShowOutdatedData: getShowOutdatedData(state),
});

const mapDispatchToProps = (dispatch: Dispatch): FunctionProperties<WorkingTimesViewStateProps> => ({
    showInfoBox: () => dispatch(widgetActions.setShowWorkingTimesInfoBox(true)),
    hideInfoBox: () => dispatch(widgetActions.setShowWorkingTimesInfoBox(false)),
    showOutdatedData: () => dispatch(widgetActions.setShowOutdatedData(true)),
});

export const WorkingTimesViewContainer = connect(mapStateToProps, mapDispatchToProps)(WorkingTimesView);
