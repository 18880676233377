/* eslint-disable react/no-children-prop */
import { ReactNode, JSX } from 'react';
import { FormattedMessage } from 'react-intl';
import { TimerValueComponent } from '../../../tables/elements/TimerValueComponent';
import StatusBar from '@rio-cloud/rio-uikit/StatusBar';

export interface DrivingTimesCellProps {
    currentMinutes?: number;
    remainingMinutes?: number;
    maximumMinutes: number;
    maximumWithoutExtensionMinutes?: number;
    testIdPrefix: string;
    headerMessageId: string;
    children?: ReactNode;
    textColor: string;
    progressBarColor: string;
}

export const calculateProgressPercent = (currentMinutes: number | undefined, maximumMinutes: number): number =>
    currentMinutes && maximumMinutes ? Math.min(currentMinutes / maximumMinutes, 1) * 100 : 0;

const calculateExtensionPercent = (
    maximumWithoutExtensionMinutes: number | undefined,
    currentMinutes: number | undefined,
    maximumMinutes: number
): number => {
    if (maximumWithoutExtensionMinutes) {
        if (currentMinutes && currentMinutes > maximumWithoutExtensionMinutes) {
            return (100 * (maximumMinutes - currentMinutes)) / maximumMinutes;
        } else {
            return (100 * (maximumMinutes - maximumWithoutExtensionMinutes)) / maximumMinutes;
        }
    }
    return 0;
};

const calculateRemainingPercent = (progressPercent: number, extensionPercent: number): number =>
    100 - progressPercent - extensionPercent;

export interface CurrentTimeProps {
    currentMinutes?: number;
    testIdPrefix: string;
    headerMessageId: string;
    children?: ReactNode;
    color: string;
}

export const CurrentTime = (props: CurrentTimeProps): JSX.Element => {
    const { currentMinutes, testIdPrefix, headerMessageId } = props;
    return (
        <div className={'text-center flex-1-0 padding-y-10'}>
            <div className={'text-size-16 text-color-gray text-medium margin-bottom-3'}>
                <FormattedMessage id={headerMessageId} />
            </div>
            <div
                className={'text-size-h4 line-height-h6 display-flex align-items-center justify-content-center'}
                data-testid={`${testIdPrefix}-current`}
            >
                <TimerValueComponent color={props.color} size={'h4'} minutes={currentMinutes} />
                {props.children}
            </div>
        </div>
    );
};

interface RemainingTimeProps {
    remainingMinutes?: number;
    testIdPrefix: string;
    color: string;
}

const RemainingTime = (props: RemainingTimeProps): JSX.Element | null => {
    const { remainingMinutes, testIdPrefix, color } = props;
    if (typeof remainingMinutes !== 'number') {
        return null;
    }
    return (
        <div className={'text-center padding-bottom-10'} data-testid={`${testIdPrefix}-remaining`}>
            <span>
                <span className={'text-color-gray'}>
                    <FormattedMessage id={'intl-msg:timed.widget.remaining'} />:{' '}
                </span>
                <TimerValueComponent
                    color={color}
                    size={'medium'}
                    emptyDataIconSize={'medium'}
                    minutes={remainingMinutes}
                />
            </span>
        </div>
    );
};

export interface ProgressBarProps {
    testIdPrefix: string;
    progressPercent: number;
    extensionPercent?: number;
    remainingPercent: number;
    color: string;
}

export const ProgressBar = (props: ProgressBarProps): JSX.Element => {
    const { testIdPrefix, progressPercent, extensionPercent, remainingPercent, color } = props;
    return (
        <div className={'border border-top-only padding-0'} data-testid={`${testIdPrefix}-progress`}>
            <StatusBar
                className={'progress margin-0 height-5 rounded-top-left-none rounded-top-right-none'}
                progress={[
                    {
                        percentage: progressPercent,
                        color,
                    },
                    {
                        percentage: remainingPercent,
                        color: 'bg-light',
                    },
                    {
                        percentage: extensionPercent ?? 0,
                        color: 'bg-lighter',
                    },
                ]}
                size={'large'}
                useProgressDivider={false}
            />
        </div>
    );
};

export const DrivingTimesCell = (props: DrivingTimesCellProps): JSX.Element => {
    const {
        currentMinutes,
        remainingMinutes,
        maximumMinutes,
        maximumWithoutExtensionMinutes,
        testIdPrefix,
        headerMessageId,
        children,
        textColor,
        progressBarColor,
    } = props;
    const progressPercent = calculateProgressPercent(currentMinutes, maximumMinutes);
    const extensionPercent = calculateExtensionPercent(maximumWithoutExtensionMinutes, currentMinutes, maximumMinutes);
    const remainingPercent = calculateRemainingPercent(progressPercent, extensionPercent);

    return (
        <>
            <CurrentTime
                headerMessageId={headerMessageId}
                testIdPrefix={testIdPrefix}
                currentMinutes={currentMinutes}
                children={children}
                color={textColor}
            />
            <RemainingTime testIdPrefix={testIdPrefix} remainingMinutes={remainingMinutes} color={textColor} />
            <ProgressBar
                testIdPrefix={testIdPrefix}
                progressPercent={progressPercent}
                remainingPercent={remainingPercent}
                extensionPercent={extensionPercent}
                color={progressBarColor}
            />
        </>
    );
};
