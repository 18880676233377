import { JSX } from 'react';
import { DrivingTimesDisplayItem } from '../../../tables/drivingtimes/types';
import { FormattedMessage } from 'react-intl';
import { Activities, ActivityConfig } from '../../../enums/Activities';
import Activity from '@rio-cloud/rio-uikit/Activity';
import { formatDuration } from '../../../formatting/TimeFormatting';
import { StatusDuration } from '../../../tables/cells/types';
import { TrackingAction, trackingAttributes, TrackingCategory } from '../../../../configuration/setup/googleTagManager';
import { TableColumn } from '../../../enums/TableColumn';

const DriverNameComponent = ({ displayItem }: { displayItem: DrivingTimesDisplayItem }): JSX.Element => {
    return displayItem[TableColumn.DRIVER] ? (
        <div data-testid={'DriverName'} className={'text-center text-size-h3 margin-bottom-15'}>
            {displayItem[TableColumn.DRIVER]}
        </div>
    ) : (
        <></>
    );
};

interface ActivityIconProps {
    activityType: Activities;
    activityStatus: StatusDuration | undefined;
}

const ActivityIconWrapper = ({ activityType, activityStatus }: ActivityIconProps): JSX.Element => {
    const activityConfig = ActivityConfig[activityType];
    if (activityStatus && activityStatus.status === activityType) {
        return (
            <div className={'display-flex flex-column'}>
                <Activity
                    activity={activityConfig.uikitActivity}
                    duration={formatDuration(activityStatus.duration)}
                    isOutdated={false}
                    bsSize={'xl'}
                />
                <span className={`text-color-${activityConfig.color} text-center text-medium margin-top-5`}>
                    <FormattedMessage id={activityConfig.translationId} />
                </span>
            </div>
        );
    } else {
        return <Activity activity={activityConfig.uikitActivity} isOutdated={true} bsSize={'xl'} />;
    }
};

const ActivityInfoComponent = ({ activityStatus }: { activityStatus: StatusDuration | undefined }): JSX.Element => (
    <div className={'display-flex justify-content-center'}>
        <span className={'margin-left-10 margin-right-10'} data-testid={'status-available-icon'}>
            <ActivityIconWrapper activityType={Activities.AVAILABLE} activityStatus={activityStatus} />
        </span>
        <span className={'margin-left-10 margin-right-10'} data-testid={'status-working-icon'}>
            <ActivityIconWrapper activityType={Activities.DRIVING} activityStatus={activityStatus} />
        </span>
        <span className={'margin-left-10 margin-right-10'} data-testid={'status-driving-icon'}>
            <ActivityIconWrapper activityType={Activities.WORKING} activityStatus={activityStatus} />
        </span>
        <span className={'margin-left-10 margin-right-10'} data-testid={'status-resting-icon'}>
            <ActivityIconWrapper activityType={Activities.RESTING} activityStatus={activityStatus} />
        </span>
    </div>
);

const trackingClickAttributes = trackingAttributes(
    TrackingCategory.WIDGET_VIEW,
    TrackingAction.CLICK_ON_LINK_TO_DRIVINGTIMES_SPA
);

const LinkToSpa = ({ assetId }: { assetId: string }): JSX.Element => (
    <a
        href={`https://drivingtime.rio.cloud/#realtime?assetIds=${assetId}`}
        target={'_top'}
        data-testid={'spa-link'}
        {...trackingClickAttributes}
    >
        <FormattedMessage id={'intl-msg:timed.widget.link.spa'} />
    </a>
);

export const DriverActivityOverview = (props: { displayItem: DrivingTimesDisplayItem }): JSX.Element => {
    const { displayItem } = props;

    return (
        <div className={'border rounded margin-bottom-20'}>
            <div className={'padding-top-20 padding-bottom-25'}>
                <DriverNameComponent displayItem={displayItem} />
                <ActivityInfoComponent activityStatus={displayItem[TableColumn.STATUS_DURATION]} />
            </div>
            <div className={'border border-top-only text-center padding-10'}>
                <LinkToSpa assetId={displayItem.assetId} />
            </div>
        </div>
    );
};
