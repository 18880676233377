import { JSX } from 'react';
import { FormattedMessage } from 'react-intl';

interface InfoBoxProps {
    shouldShowInfoBox: boolean;
    hideInfoBox: () => void;
}

export const GeneralInfoBox = (props: InfoBoxProps): JSX.Element | null => {
    const { hideInfoBox } = props;
    return props.shouldShowInfoBox ? (
        <div className={'alert alert-info alert-dismissible margin-bottom-20'}>
            <button
                type="button"
                className="btn btn-icon-only close"
                data-dismiss="alert"
                onClick={hideInfoBox}
                aria-label={'close-button'}
            >
                <span className="rioglyph rioglyph-remove"></span>
            </button>
            <p className={'margin-top-0 text-size-16 text-medium'}>
                <FormattedMessage id={'intl-msg:timed.widget.workingTimes.infoBox.headline'} />
            </p>
            <div className="margin-top-5">
                <ul>
                    <li>
                        <FormattedMessage id={'intl-msg:timed.widget.workingTimes.infoBox.content.sameCompany'} />
                    </li>
                    <li>
                        <FormattedMessage id={'intl-msg:timed.widget.workingTimes.infoBox.content.rioBoxTurnedOn'} />
                    </li>
                </ul>
            </div>
            <div className="margin-top-5">
                <i>
                    <FormattedMessage id={'intl-msg:timed.widget.workingTimes.infoBox.content.bookCompliant'} />
                </i>
            </div>
        </div>
    ) : null;
};
