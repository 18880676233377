import { JSX } from 'react';
import { EmptyDataIconWithTooltip } from '../elements/EmptyDataIcon';
import { Moment } from 'moment';
import FormattedTimeStamp from '../../formatting/FormattedTimeStamp';
import { TooltipWrapper } from '../../onboarding/TooltipWrapper';
import { getTimeStampType, TimeStampCategoryConfig } from '../../enums/TimeStampCategory';
import { FormattedMessage } from 'react-intl';

interface TimestampCellProps {
    value?: Moment;
    showRestingSince?: boolean;
}

export const TimestampCell = (props: TimestampCellProps): JSX.Element => {
    const { value } = props;

    if (!value) {
        return <EmptyDataIconWithTooltip />;
    }

    const timeStampType = getTimeStampType(value);
    const configElement = TimeStampCategoryConfig[timeStampType];

    const restingSinceLine = props.showRestingSince ? (
        <>
            <FormattedMessage id={'intl-msg:timed.driving-time-table.restingSince'} />
            <br />
        </>
    ) : null;

    return (
        <div className={'display-flex align-items-center'}>
            <TooltipWrapper tooltipId={configElement.translationId}>
                <div className={'display-inline-block'}>
                    <span className={`rioglyph rioglyph-sphere text-${configElement.color} margin-right-5`} />
                </div>
            </TooltipWrapper>
            <div className={'text-size-small display-inline-block'}>
                {restingSinceLine}
                <FormattedTimeStamp date={value} displayYear={false} />
            </div>
        </div>
    );
};
