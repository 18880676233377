import { JSX } from 'react';
import { TableModeButtonContainer } from './TableModeButtons.container';
import { ActivitySelectContainer } from './tablefilters/ActivitySelect.container';
import { TimeStampSelectContainer } from './tablefilters/TimeStampSelect.container';
import { ModeSwitchButtonsContainer } from './tableswitches/ModeSwitchButtons.container';
import { HistoricViewSwitchButtonsContainer } from './tableswitches/HistoricViewSwitchButtons.container';
import { connect } from 'react-redux';
import {
    getIsStateChangesDisplayed,
    getReceivedTimestampOfDrivingTimes,
    getReceivedTimestampOfStateChanges,
} from '../redux/realtime.redux';
import { TableSettingDialogContainer } from './tablesettings/TableSettingDialog.container';
import { LiveClock } from './LiveClock';
import { TimeRangeSelectionContainer } from './tablefilters/TimeRangeSelection.container';
import moment, { Moment } from 'moment';
import { Tips } from '../../onboarding/types';
import { ConnectedOnboardingTip } from '../../onboarding/ConnectedOnboardingTip';
import { State } from '../../../reducers';
import { FormattedMessage } from 'react-intl';

interface RealTimeHeaderStateProps {
    isStateChangesDisplayed: boolean;
    receivedTimestampDrivingTimes: string | undefined;
    receivedTimestampStateChanges: string | undefined;
}

type RealTimeHeaderProps = RealTimeHeaderStateProps;

const RealTimeHeader = (props: RealTimeHeaderProps): JSX.Element => {
    const { isStateChangesDisplayed, receivedTimestampDrivingTimes, receivedTimestampStateChanges } = props;

    const GetTimeSelectionComponent = (): JSX.Element => (
        <ConnectedOnboardingTip
            tipType={Tips.TIME_RANGE_SELECTION}
            titleTranslationId={'intl-msg:timed.glossary.onboarding.activities.timeRangeSelection.title'}
            contentTranslationId={'intl-msg:timed.glossary.onboarding.activities.timeRangeSelection.text'}
        >
            <TimeRangeSelectionContainer />
        </ConnectedOnboardingTip>
    );

    return (
        <div className={'table-toolbar'}>
            <div className={'table-toolbar-container'}>
                <div className={'table-toolbar-group-left'}>
                    <div className={'table-toolbar-column table-toolbar-column-spacer'}>
                        {<HistoricViewSwitchButtonsContainer />}
                    </div>
                    <div className={'table-toolbar-column'}>
                        <ActivitySelectContainer />
                    </div>
                    <div className={'table-toolbar-column table-toolbar-column-spacer'}>
                        <TimeStampSelectContainer />
                    </div>
                    <div className={'table-toolbar-column display-flex flex-wrap flex-row align-items-end'}>
                        {isStateChangesDisplayed ? <GetTimeSelectionComponent /> : <ModeSwitchButtonsContainer />}
                    </div>
                </div>
                <div className={'table-toolbar-group-right'}>
                    <div className={'table-toolbar-column table-toolbar-column-spacer'}>
                        <label>
                            <FormattedMessage id={'intl-msg:timed.common.lastUpdated'} />
                        </label>{' '}
                        <div className={'margin-bottom-5'}>
                            <LiveClock
                                date={
                                    isStateChangesDisplayed
                                        ? moment(receivedTimestampDrivingTimes)
                                        : moment(receivedTimestampStateChanges)
                                }
                            />
                        </div>
                    </div>
                    <div
                        className={`table-toolbar-column ${
                            isStateChangesDisplayed ? '' : 'table-toolbar-column-spacer'
                        }`}
                    >
                        <TableModeButtonContainer />
                    </div>
                    <div className={'table-toolbar-column'}>
                        <TableSettingDialogContainer />
                    </div>
                </div>
            </div>
        </div>
    );
};

const getTimeAsStringWithMinutePrecision = (date?: Moment): string | undefined =>
    date ? date.seconds(0).milliseconds(0).toISOString() : undefined;

const mapStateToProps = (state: State): RealTimeHeaderStateProps => ({
    isStateChangesDisplayed: getIsStateChangesDisplayed(state),
    receivedTimestampDrivingTimes: getTimeAsStringWithMinutePrecision(getReceivedTimestampOfDrivingTimes(state)),
    receivedTimestampStateChanges: getTimeAsStringWithMinutePrecision(getReceivedTimestampOfStateChanges(state)),
});

export const RealTimeHeaderContainer = connect(mapStateToProps)(RealTimeHeader);
