import { JSX } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { DrivingTimesDisplayItem, TableRowProps } from './types';
import { VehicleCell } from '../cells/VehicleCell';
import { DriverCell } from '../cells/DriverCell';
import { CurrentDrivingTimeCell } from '../cells/CurrentDrivingTimeCell';
import { RemainingDrivingTimeCell } from '../cells/RemainingDrivingTimeCell';
import { CurrentRestingCell } from '../cells/CurrentRestingCell';
import { RemainingRestingCell } from '../cells/RemainingRestingCell';
import { CurrentDrivingTodayCell } from '../cells/CurrentDrivingTodayCell';
import { RemainingDrivingTodayCell } from '../cells/RemainingDrivingTodayCell';
import { CurrentDrivingWeekCell } from '../cells/CurrentDrivingWeekCell';
import { RemainingDrivingWeekCell } from '../cells/RemainingDrivingWeekCell';
import { CurrentDrivingDoubleWeekCell } from '../cells/CurrentDrivingDoubleWeekCell';
import { RemainingDrivingDoubleWeekCell } from '../cells/RemainingDrivingDoubleWeekCell';
import { RemainingReducedRestPeriodsCell } from '../cells/RemainingReducedRestPeriodsCell';
import { TimestampCell } from '../cells/TimestampCell';
import { StatusDurationCell } from '../cells/StatusDurationCell';
import { TrackingAction, trackingAttributes, TrackingCategory } from '../../../configuration/setup/googleTagManager';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import { getTimeStampType, TimeStampCategory } from '../../enums/TimeStampCategory';
import { Moment } from 'moment';
import { WeeklyRestMinimumCell } from '../cells/WeeklyRestMinimum';
import { getColumnHeaderMessageId } from '../header/columnHeaderFormattingService';
import { TableColumn } from '../../enums/TableColumn';
import { WorkingTimesTableCell } from '../cells/WorkingTimesTableCell';
import { Activities } from '../../enums/Activities';

const renderTableData = (drivingTimesItem: DrivingTimesDisplayItem, column: TableColumn): JSX.Element | null => {
    switch (column) {
        case TableColumn.VEHICLE:
            return <VehicleCell value={drivingTimesItem[column]} />;
        case TableColumn.DRIVER:
            return <DriverCell value={drivingTimesItem[column]} />;
        case TableColumn.STATUS_DURATION:
            return <StatusDurationCell value={drivingTimesItem[column]} />;
        case TableColumn.CONTINUOUS_DRIVING:
            return <CurrentDrivingTimeCell value={drivingTimesItem[column]} />;
        case TableColumn.REMAINING_CURRENT_DRIVING_TIME:
            return <RemainingDrivingTimeCell value={drivingTimesItem[column]} />;
        case TableColumn.RESTING_TODAY_CURRENT:
            return <CurrentRestingCell value={drivingTimesItem[column]} />;
        case TableColumn.REMAINING_TIME_OF_CURRENT_BREAK:
            return <RemainingRestingCell value={drivingTimesItem[column]} />;
        case TableColumn.DRIVING_TODAY_CURRENT:
            return <CurrentDrivingTodayCell value={drivingTimesItem[column]} />;
        case TableColumn.REMAINING_DRIVING_TODAY_CURRENT:
            return <RemainingDrivingTodayCell value={drivingTimesItem[column]} />;
        case TableColumn.DRIVING_WEEKLY_CURRENT:
            return <CurrentDrivingWeekCell value={drivingTimesItem[column]} />;
        case TableColumn.REMAINING_WEEK_DRIVING_REMAINING:
            return <RemainingDrivingWeekCell value={drivingTimesItem[column]} />;
        case TableColumn.DRIVING_TWO_WEEKLY_CURRENT:
            return <CurrentDrivingDoubleWeekCell value={drivingTimesItem[column]} />;
        case TableColumn.REMAINING_DRIVING_TWO_WEEKLY_CURRENT:
            return <RemainingDrivingDoubleWeekCell value={drivingTimesItem[column]} />;
        case TableColumn.MINIMUM_DAILY_REST_MINIMUM:
        case TableColumn.REMAINING_MINIMUM_DAILY_REST_MINIMUM:
            return <RemainingReducedRestPeriodsCell value={drivingTimesItem[column]} />;
        case TableColumn.WEEKLY_REST_MINIMUM:
            return <WeeklyRestMinimumCell value={drivingTimesItem[column]} />;
        case TableColumn.CURRENT_DAILY_WORKING_TIME:
            return (
                <WorkingTimesTableCell
                    selectedValue={drivingTimesItem[column]}
                    restValue={drivingTimesItem[TableColumn.REMAINING_DAILY_WORKING_TIME]}
                    showRemaining={false}
                />
            );
        case TableColumn.REMAINING_DAILY_WORKING_TIME:
            return (
                <WorkingTimesTableCell
                    selectedValue={drivingTimesItem[column]}
                    restValue={drivingTimesItem[TableColumn.CURRENT_DAILY_WORKING_TIME]}
                    showRemaining={true}
                />
            );
        case TableColumn.CURRENT_WEEKLY_WORKING_TIME:
            return (
                <WorkingTimesTableCell
                    selectedValue={drivingTimesItem[column]}
                    restValue={drivingTimesItem[TableColumn.REMAINING_WEEKLY_WORKING_TIME]}
                    showRemaining={false}
                />
            );
        case TableColumn.REMAINING_WEEKLY_WORKING_TIME:
            return (
                <WorkingTimesTableCell
                    selectedValue={drivingTimesItem[column]}
                    restValue={drivingTimesItem[TableColumn.CURRENT_WEEKLY_WORKING_TIME]}
                    showRemaining={true}
                />
            );
        case TableColumn.DRIVING_TIMES_TIMESTAMP:
            return <TimestampCell value={drivingTimesItem[column]} />;
        case TableColumn.WORKING_TIMES_TIMESTAMP:
            return <TimestampCell value={drivingTimesItem[column]} showRestingSince={isResting(drivingTimesItem)} />;
        default:
            return null;
    }
};

const isResting = (row: DrivingTimesDisplayItem): boolean => {
    return row[TableColumn.STATUS_DURATION]?.status === Activities.RESTING;
};

const trackSelectTableItem = trackingAttributes(
    TrackingCategory.DRIVING_TIMES_VIEW,
    TrackingAction.SELECT_TABLE_ITEM,
    'show details sidebar'
);

const getDisableClassName = (timestamp: Moment | undefined): string => {
    if (timestamp) {
        const timeStampType = getTimeStampType(timestamp);
        return timeStampType === TimeStampCategory.OUTDATED ? 'disabled' : '';
    }
    return 'disabled';
};

const DrivingTimesTableRowRaw = (props: TableRowProps & WrappedComponentProps): JSX.Element => {
    const { columns, drivingTimesItem, intl, onRowSelected, tableViewMode } = props;

    const formatProgressBarInCardViewModes =
        tableViewMode === TableViewToggles.VIEW_TYPE_TABLE ? '' : 'display-flex flex-column';

    const activateRowIfSelected = drivingTimesItem.isActive ? 'active' : '';
    const disabledRowIfDateIsOutdated = drivingTimesItem[TableColumn.DRIVING_TIMES_TIMESTAMP]
        ? getDisableClassName(drivingTimesItem[TableColumn.DRIVING_TIMES_TIMESTAMP])
        : '';

    return (
        <tr
            onClick={onRowSelected}
            data-key={drivingTimesItem.id}
            className={`${activateRowIfSelected} ${disabledRowIfDateIsOutdated} pointer-events-all cursor-pointer`}
            {...trackSelectTableItem}
        >
            {columns.map((column) => (
                <td
                    key={column}
                    className={formatProgressBarInCardViewModes}
                    data-field={intl.formatMessage({ id: getColumnHeaderMessageId(column) })}
                >
                    <span>{renderTableData(drivingTimesItem, column)}</span>
                </td>
            ))}
        </tr>
    );
};

export const DrivingTimesTableRow = injectIntl(DrivingTimesTableRowRaw);
