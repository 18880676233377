import { JSX } from 'react';
import { FormattedMessage } from 'react-intl';

import { ActivityConfig, INACTIVE_COLOR } from '../enums/Activities';
import { InlineTimeStamp } from './elements/InlineTimestamp';
import { getTimeStampType, TimeStampCategory } from '../enums/TimeStampCategory';
import RioglyphIcon from '../wrapper/RioglyphIcon';
import { DurationComponent } from '../formatting/TimeFormatting';
import { Moment } from 'moment';
import { StatusDuration } from '../tables/cells/types';

/*
 * TODO: check if this is a truck and display the concerning message
 */

export interface DetailsListProps {
    timeStamp: Moment;
    receivedTimestamp?: Moment;
    statusDuration?: StatusDuration;
    driverName?: string;
    vehicleName?: string;
}

// eslint-disable-next-line max-lines-per-function
const DetailsList = (props: DetailsListProps): JSX.Element => {
    const { timeStamp, receivedTimestamp, statusDuration } = props;

    const activityConfig = statusDuration ? ActivityConfig[statusDuration.status] : undefined;
    const activityColor =
        getTimeStampType(timeStamp, receivedTimestamp) !== TimeStampCategory.OUTDATED && activityConfig
            ? activityConfig.color
            : INACTIVE_COLOR;

    return (
        <div className={'row'}>
            <div className={'col-xs-12'}>
                <div className={'row text-size-14'}>
                    <div className={'col-xs-7'}>
                        <div className={'form-group'}>
                            <label className={'margin-bottom-0'}>
                                <FormattedMessage id={'intl-msg:glossary.driver'} />
                            </label>

                            {props.driverName ? (
                                <div className={'text-size-large ellipsis-1 padding-bottom-3'}>{props.driverName}</div>
                            ) : (
                                <div>
                                    <RioglyphIcon color={INACTIVE_COLOR} type={'question-sign'} size={'larger'} />
                                </div>
                            )}
                        </div>
                        <div className={'form-group'}>
                            <label className={'margin-bottom-0'}>
                                <FormattedMessage id={'intl-msg:timed.common.timestamp'} />
                            </label>
                            <div className={'text-size-large ellipsis-1'}>
                                <InlineTimeStamp value={timeStamp} receivedTimestamp={receivedTimestamp} />
                            </div>
                        </div>
                    </div>
                    <div className={'col-xs-5'}>
                        <div className={'form-group'}>
                            <label className={'margin-bottom-0'}>
                                <FormattedMessage id={'intl-msg:glossary.vehicle'} />
                            </label>

                            {props.vehicleName ? (
                                <div className={'text-size-large ellipsis-1 padding-bottom-3'}>
                                    {' '}
                                    {props.vehicleName}{' '}
                                </div>
                            ) : (
                                <div>
                                    {' '}
                                    <RioglyphIcon color={INACTIVE_COLOR} type={'question-sign'} size={'larger'} />{' '}
                                </div>
                            )}
                        </div>
                        <div className={'display-flex'}>
                            <div className={'form-group'}>
                                <div>
                                    <label className={'margin-bottom-0'}>
                                        <FormattedMessage id={'intl-msg:timed.common.activity'} />
                                    </label>

                                    {statusDuration && typeof statusDuration.duration === 'number' ? (
                                        <div className={'text-size-large padding-bottom-3'}>
                                            <strong>
                                                <DurationComponent
                                                    minutes={statusDuration.duration}
                                                    displayColor={activityColor}
                                                />
                                            </strong>
                                        </div>
                                    ) : (
                                        <div>
                                            <RioglyphIcon
                                                color={INACTIVE_COLOR}
                                                type={'question-sign'}
                                                size={'larger'}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={'text-size-12 display-flex align-self-start'}>
                                {activityConfig ? (
                                    <span
                                        className={
                                            `rioglyph rioglyph-${activityConfig.type} ` +
                                            `text-color-white bg-${activityColor} border-radius-100pct padding-5 ` +
                                            'text-size-200pct margin-left-10 margin-top-5 rounded-circle'
                                        }
                                    />
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailsList;
