import { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Dispatch } from 'redux';
import { fetchLatestPositionThunk } from '../detailssidebar/map/thunk/latestPosition.thunk';
import { getSelectedDrivingTimesRowId } from './redux/realtime.redux';
import isEqual from 'lodash/fp/isEqual';
import { realtimeSidebarActions } from '../detailssidebar/redux/detailsSidebar.redux';
import { fetchDataThunk } from '../data/data.thunk';
import { DELAY_TO_HAVE_REAL_TIME_DATA, ITEM_POLLING_INTERVAL } from './RealTimeConstants';
import { fetchDrivingTimesDataThunk } from './thunks/drivingTimesData.thunk';
import { fetchStateChangesDataThunk } from './thunks/stateChangesData.thunk';
import { State } from '../../reducers';

interface RealTimeFetcherStateProps {
    selectedDrivingTimesRowId: string | undefined;
}

interface RealTimeFetcherDispatchProps {
    fetchData: () => void;
    fetchRealTimeData: (isLoadingSpinnerDisplayed: boolean) => void;
    fetchLatestPositionData: () => void;
    setLastPositionToNull: () => void;
}

type RealTimeFetcherProps = RealTimeFetcherStateProps & RealTimeFetcherDispatchProps;

// converting this into React lifecycle hooks results in https://reactjs.org/docs/error-decoder.html/?invariant=185
class RealTimeFetcher extends Component<RealTimeFetcherProps> {
    private dataPollingInterval?: number;

    componentDidMount(): void {
        this.initialFetching();
        this.initializeScheduledFetching();
    }

    componentDidUpdate(prevProps: RealTimeFetcherProps): void {
        if (this.hasSelectedRowInSidebarChanged(prevProps)) {
            this.props.setLastPositionToNull();
            this.props.fetchLatestPositionData();
        }
        if (this.hasSidebarBeenClosed(prevProps)) {
            this.props.setLastPositionToNull();
        }
    }

    componentWillUnmount(): void {
        window.clearInterval(this.dataPollingInterval);
        this.dataPollingInterval = undefined;
    }

    render(): null {
        return null;
    }

    private initialFetching = (): void => {
        const { fetchData, fetchRealTimeData, fetchLatestPositionData, selectedDrivingTimesRowId } = this.props;
        fetchData();
        fetchRealTimeData(true);
        if (selectedDrivingTimesRowId) {
            window.setTimeout(() => fetchLatestPositionData(), DELAY_TO_HAVE_REAL_TIME_DATA); // realTimeDate must be there to determine assetId of selected table row
        }
    };

    private initializeScheduledFetching = (): void => {
        const { fetchRealTimeData, fetchLatestPositionData, selectedDrivingTimesRowId } = this.props;
        this.dataPollingInterval = window.setInterval(() => {
            fetchRealTimeData(false);
            if (selectedDrivingTimesRowId) {
                fetchLatestPositionData();
            }
        }, ITEM_POLLING_INTERVAL);
    };

    private hasSelectedRowInSidebarChanged(prevProps: RealTimeFetcherProps): boolean {
        return (
            !!this.props.selectedDrivingTimesRowId &&
            !isEqual(prevProps.selectedDrivingTimesRowId, this.props.selectedDrivingTimesRowId)
        );
    }

    private hasSidebarBeenClosed(prevProps: RealTimeFetcherProps): boolean {
        return !this.props.selectedDrivingTimesRowId && !!prevProps.selectedDrivingTimesRowId;
    }
}

const mapStateToProps = (state: State): RealTimeFetcherStateProps => ({
    selectedDrivingTimesRowId: getSelectedDrivingTimesRowId(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<Dispatch, State, any>): RealTimeFetcherDispatchProps => ({
    fetchData: (): void => {
        dispatch(fetchDataThunk);
    },
    fetchRealTimeData: (isLoadingSpinnerDisplayed: boolean): void => {
        dispatch(fetchDrivingTimesDataThunk(isLoadingSpinnerDisplayed));
        dispatch(fetchStateChangesDataThunk(isLoadingSpinnerDisplayed));
    },
    fetchLatestPositionData: (): void => {
        dispatch(fetchLatestPositionThunk());
    },
    setLastPositionToNull: (): void => {
        dispatch(realtimeSidebarActions.resetLastPosition());
    },
});

export const RealTimeFetcherContainer = connect(mapStateToProps, mapDispatchToProps)(RealTimeFetcher);
