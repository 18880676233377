import { JSX, ReactNode } from 'react';

import Tooltip, { TooltipWidth } from '@rio-cloud/rio-uikit/Tooltip';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import { injectIntl, WrappedComponentProps } from 'react-intl';

interface TooltipComponentProps {
    children: ReactNode;
    tooltip?: string;
    tooltipId?: string;
    tooltipSuffix?: string;
    width?: TooltipWidth;
}

const TooltipComponent = (props: TooltipComponentProps & WrappedComponentProps): JSX.Element => {
    const { children, tooltip, tooltipId, intl, tooltipSuffix = '', width = 'auto' } = props;

    if (!tooltip && !tooltipId) {
        return <div>{children}</div>;
    }

    return (
        <OverlayTrigger
            placement={'top'}
            delay={300}
            overlay={
                <Tooltip id={'tooltip'} className={'top'} width={width}>
                    {tooltipId ? intl.formatMessage({ id: tooltipId }) + tooltipSuffix : tooltip}
                </Tooltip>
            }
        >
            {children}
        </OverlayTrigger>
    );
};

export const TooltipWrapper = injectIntl(TooltipComponent);
