import { JSX } from 'react';
import { FormattedMessage } from 'react-intl';

import { Explanation } from './Explanation';
import TimedTableSettingsStep1 from './assets/timedTableSettingsStep1.png';
import TimedTableSettingsStep2 from './assets/timedTableSettingsStep2.png';
import TimedTableSettingsStep3 from './assets/timedTableSettingsStep3.png';

const pictureStyle = 'margin-10 width-100pct';

const steps = [
    {
        icon: '1',
        label: <FormattedMessage id={'intl-msg:timed.glossary.tableSettings.step1.title'} />,
    },
    {
        icon: '2',
        label: <FormattedMessage id={'intl-msg:timed.glossary.tableSettings.step2.title'} />,
    },
    {
        icon: '3',
        label: <FormattedMessage id={'intl-msg:timed.glossary.tableSettings.step3.title'} />,
    },
];

export const getExplanation = (step: number): JSX.Element => {
    switch (step) {
        case 0:
            return (
                <div>
                    <div>
                        <FormattedMessage id={'intl-msg:timed.glossary.tableSettings.step1.text'} />
                    </div>
                    <img src={TimedTableSettingsStep1} alt={'TimedTableSettingsStep1'} className={`${pictureStyle}`} />
                </div>
            );
        case 1:
            return (
                <div>
                    <div>
                        <FormattedMessage id={'intl-msg:timed.glossary.tableSettings.step2.text'} />
                    </div>
                    <img src={TimedTableSettingsStep2} alt={'TimedTableSettingsStep2'} className={`${pictureStyle}`} />
                </div>
            );
        case 2:
            return (
                <div>
                    <div>
                        <FormattedMessage id={'intl-msg:timed.glossary.tableSettings.step3.text'} />
                    </div>
                    <img src={TimedTableSettingsStep3} alt={'TimedTableSettingsStep3'} className={`${pictureStyle}`} />
                </div>
            );
        default:
            return <div />;
    }
};

export const TableSettingsExplanation = (): JSX.Element => (
    <Explanation steps={steps} getExplanation={getExplanation} />
);
