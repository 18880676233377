import { JSX } from 'react';
import { EmptyDataIconWithTooltip } from '../elements/EmptyDataIcon';
import { TimeScaleBarComponent } from '../elements/TimeScaleBarComponent';
import { DurationAndItems } from './types';
import { Activities, ActivityConfig, INACTIVE_COLOR } from '../../enums/Activities';
import { MAXIMUM_CONTINUOUS_DRIVING_MINUTES } from '../../legalDrivingRestrictions';

interface RemainingDrivingTimeCellProps {
    value?: DurationAndItems;
}

export const RemainingDrivingTimeCell = (props: RemainingDrivingTimeCellProps): JSX.Element => {
    const { value } = props;

    if (!value || value.duration === undefined) {
        return <EmptyDataIconWithTooltip />;
    }

    const percentageOfTime = (100 * value.duration) / MAXIMUM_CONTINUOUS_DRIVING_MINUTES;

    const progressParts = [
        {
            percentage: 100 - percentageOfTime,
            color: INACTIVE_COLOR,
            tooltipId: 'intl-msg:timed.driving-time-table.maximum4h30min',
        },
        {
            percentage: percentageOfTime,
            color: ActivityConfig[Activities.DRIVING].color,
            tooltipId: 'intl-msg:timed.driving-time-table.timeUntilNextBreakOrRest',
        },
    ];

    return (
        <TimeScaleBarComponent
            numberValue={value.duration}
            progressParts={progressParts}
            infringementLevel={value?.level}
            textColor={ActivityConfig[Activities.DRIVING].color}
        />
    );
};
