import { JSX } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { StateActivity } from '../statechanges/types';
import { TooltipWrapper } from '../../onboarding/TooltipWrapper';
import moment, { Moment } from 'moment';
import { ActivityConfig } from '../../enums/Activities';

interface TimeBlockProps {
    activity: StateActivity;
    rangeStart: Moment;
    forcedColor?: string;
    rangeEnd?: Moment;
    showTooltip?: boolean;
}

const FULL_WIDTH = 100;

const cutActivityEnd = (rangeEnd: moment.Moment) => (rangeEnd.isSame(moment(), 'day') ? moment() : rangeEnd);

export const getStateStyle = (activity: StateActivity, rangeStart: Moment, rangeEnd: Moment): Object => {
    if (!activity.from || activity.from.isAfter(rangeEnd) || activity.to.isBefore(rangeStart)) {
        return {
            width: '0%',
            marginLeft: '0%',
            display: 'none',
            visibility: 'collapse',
        };
    }

    const lengthOfDisplayInMinutes = rangeEnd.diff(rangeStart, 'minutes');

    const normalisedStartOfActivity = activity.from.isBefore(rangeStart) ? rangeStart : activity.from;
    const normalisedEndOfActivity = activity.to.isAfter(rangeEnd) ? cutActivityEnd(rangeEnd) : activity.to;
    const lengthOfActivityInMinutes = normalisedEndOfActivity.diff(normalisedStartOfActivity, 'minutes');
    const distanceToBeginOfDisplayInMinutes = normalisedStartOfActivity.diff(rangeStart, 'minutes');

    return {
        width: `${(FULL_WIDTH * lengthOfActivityInMinutes) / lengthOfDisplayInMinutes}%`,
        marginLeft: `${(FULL_WIDTH * distanceToBeginOfDisplayInMinutes) / lengthOfDisplayInMinutes}%`,
        minWidth: '1px',
        height: '100%',
        position: 'absolute',
    };
};

const TimeBlockRaw = ({
    activity,
    intl,
    forcedColor,
    rangeStart,
    rangeEnd,
    showTooltip = true,
}: TimeBlockProps & WrappedComponentProps): JSX.Element | null => {
    const { color, translationId } = ActivityConfig[activity.activity];

    const translatedActivity = intl.formatMessage({ id: translationId });
    const from = intl.formatTime(activity.from.toDate());
    const to = intl.formatTime(activity.to.toDate());
    const tooltip = showTooltip ? `${translatedActivity} ${from} - ${to}` : undefined;

    return rangeEnd ? (
        <TooltipWrapper tooltip={tooltip}>
            <div
                className={forcedColor ? forcedColor : `bg-${color}`}
                style={getStateStyle(activity, rangeStart, rangeEnd)}
            />
        </TooltipWrapper>
    ) : null;
};

export const TimeBlock = injectIntl(TimeBlockRaw);
