import { JSX } from 'react';
import { formatDuration } from '../../formatting/TimeFormatting';
import { EmptyDataIconWithTooltip } from './EmptyDataIcon';

interface TimerRatingTsComponentProps {
    minutes?: number;
    color: string;
    size: string;
    emptyDataIconSize?: string;
}

export const TimerValueComponent = (props: TimerRatingTsComponentProps): JSX.Element => {
    const { minutes, color = 'highlight', size = 'normal' } = props;

    if (typeof minutes !== 'number') {
        return <EmptyDataIconWithTooltip />;
    }

    return <strong className={`text-color-${color} text-size-${size} `}>{formatDuration(minutes)}</strong>;
};
