import { JSX } from 'react';
import Multiselect from '@rio-cloud/rio-uikit/Multiselect';
import { FormattedMessage } from 'react-intl';

import RioglyphIcon from '../../../wrapper/RioglyphIcon';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ActivitySelectDispatchProps, ActivitySelectProps, ActivitySelectStateProps } from '../types';
import { getSelectedActivities, realtimeActions } from '../../redux/realtime.redux';
import { Activities, ActivityConfig } from '../../../enums/Activities';
import {
    createGoogleAnalyticsEvent,
    dataLayerPush,
    TrackingAction,
    TrackingCategory,
} from '../../../../configuration/setup/googleTagManager';
import { State } from '../../../../reducers';

// eslint-disable-next-line react/display-name
const getSelectedActivityIcons = (selectedOptions: Activities[]) => (): JSX.Element => (
    <span>
        {Object.values(Activities).map((option: Activities) => (
            <RioglyphIcon
                key={`timeFrameSelect-selected-${option}`}
                type={ActivityConfig[option].type}
                color={!selectedOptions.includes(option) ? 'light' : ActivityConfig[option].uiString}
                size={'small'}
                className={'margin-left-5 rounded-circle'}
                backgroundSize={3}
            />
        ))}
    </span>
);

const ActivitySelect = (props: ActivitySelectProps): JSX.Element => {
    const { selectedOptions, onChange } = props;

    const getLabel = (activityConfigElement: { color: string; translationId: string; type: string }): JSX.Element => (
        <span>
            <RioglyphIcon
                backgroundSize={3}
                type={activityConfigElement.type}
                color={activityConfigElement.color}
                className={'margin-right-5 rounded-circle'}
            />
            <FormattedMessage id={activityConfigElement.translationId} />
        </span>
    );

    const selectOptions = Object.values(Activities).map((activity: Activities) => ({
        id: activity,
        label: getLabel(ActivityConfig[activity]),
        selected: selectedOptions.includes(activity),
    }));

    const trackedOnChange = (selectedActivities: string[]): void => {
        onChange(selectedActivities as Activities[]);
        dataLayerPush(
            createGoogleAnalyticsEvent(
                TrackingCategory.ANY_TABLE,
                TrackingAction.CHANGE_SELECTED_ACTIVITIES,
                selectedActivities.join(', ')
            )
        );
    };

    return (
        <>
            <label>
                <FormattedMessage id={'intl-msg:timed.common.activity'} />
            </label>
            <Multiselect
                options={selectOptions}
                onChange={trackedOnChange}
                renderCounterMessage={getSelectedActivityIcons(selectedOptions)}
                placeholder={<FormattedMessage id={'intl-msg:timed.common.all'} />}
            />
        </>
    );
};

const mapDispatchToProps = (dispatch: Dispatch): ActivitySelectDispatchProps => ({
    onChange: (selectedActivities: Activities[]): void => {
        dispatch(realtimeActions.setSelectedActivities(selectedActivities));
        dispatch(realtimeActions.selectDrivingTimesTableRow());
    },
});

const mapStateToProps = (state: State): ActivitySelectStateProps => ({
    selectedOptions: getSelectedActivities(state),
});

export const ActivitySelectContainer = connect(mapStateToProps, mapDispatchToProps)(ActivitySelect);
