import { JSX } from 'react';
import ImagePreloader from '@rio-cloud/rio-uikit/ImagePreloader';
import Spinner from '@rio-cloud/rio-uikit/Spinner';

type ImagePreloaderChildProps = {
    status: string;
    image: { src: string };
};

type ImageLoaderProps = {
    src: string;
    title: string;
    className?: string;
};

export const ImageLoader = ({ src, title, className = 'margin-bottom-25' }: ImageLoaderProps): JSX.Element => (
    <ImagePreloader src={src}>
        {({ status, image }: ImagePreloaderChildProps) => {
            if (status === 'LOADED') {
                return <img className={`img-responsive ${className}`} src={image.src} alt={title} />;
            }
            return (
                <div className={`aspect-ratio-resizer ${className}`}>
                    <svg viewBox="0 0 970 347" />
                    <div>
                        <div className="content-center height-100pct">
                            <Spinner isInverse={false} />
                        </div>
                    </div>
                </div>
            );
        }}
    </ImagePreloader>
);
