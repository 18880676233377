import { JSX } from 'react';
import { DrivingTimesDisplayItem } from '../../tables/drivingtimes/types';
import { ClockCounterComponent } from '../../tables/elements/ClockCounterComponent';
import { DrivingTimesCell } from './elements/DrivingTimesCell';
import {
    MAX_NUMBER_OF_RESTING_EXCEED_PER_WEEK,
    MAXIMUM_CONTINUOUS_DRIVING_MINUTES,
    MAXIMUM_DAILY_DRIVING_TIME_EXTENDED_MINUTES,
    MAXIMUM_DAILY_DRIVING_TIME_MINUTES,
    MAXIMUM_TWO_WEEKLY_DRIVING_MINUTES,
    MAXIMUM_WEEKLY_DRIVING_MINUTES,
} from '../../legalDrivingRestrictions';
import { Activities, ActivityConfig } from '../../enums/Activities';
import { FormattedMessage } from 'react-intl';
import { LiveClock } from '../../realtime/header/LiveClock';
import { TableColumn } from '../../enums/TableColumn';

interface DrivingTimesProps {
    displayItem: DrivingTimesDisplayItem;
}

const DrivingTimesHeaderComponent = (props: DrivingTimesProps): JSX.Element => (
    <div className={'display-flex justify-content-between padding-bottom-5'} data-testid={'driving-times-header'}>
        <div>
            <span className={'rioglyph rioglyph-status-driving text-color-status-driving padding-right-3'} />
            <FormattedMessage id={'intl-msg:timed.common.drivingTimes.label'} />
        </div>
        <div>
            <LiveClock date={props.displayItem[TableColumn.DRIVING_TIMES_TIMESTAMP]} />
        </div>
    </div>
);

export const DrivingTimesView = (props: DrivingTimesProps): JSX.Element | null => {
    const { displayItem } = props;

    if (!displayItem) {
        return null;
    }

    return (
        <div className={'margin-bottom-20'}>
            <DrivingTimesHeaderComponent displayItem={displayItem} />
            <div className={'display-grid grid-cols-2 gap-20'}>
                <div className={'display-flex flex-column border rounded'}>
                    <DrivingTimesCell
                        testIdPrefix={'continuous-driving-time'}
                        currentMinutes={displayItem[TableColumn.CONTINUOUS_DRIVING]?.duration}
                        remainingMinutes={displayItem[TableColumn.REMAINING_CURRENT_DRIVING_TIME]?.duration}
                        maximumMinutes={MAXIMUM_CONTINUOUS_DRIVING_MINUTES}
                        headerMessageId={'intl-msg:timed.common.continuousDriving'}
                        textColor={'status-driving'}
                        progressBarColor={'progress-bar-status-driving'}
                    />
                </div>
                <div className={'display-flex flex-column border rounded'}>
                    <DrivingTimesCell
                        testIdPrefix={'daily-driving-time'}
                        currentMinutes={displayItem[TableColumn.DRIVING_TODAY_CURRENT]?.duration}
                        remainingMinutes={displayItem[TableColumn.REMAINING_DRIVING_TODAY_CURRENT]?.duration}
                        maximumMinutes={MAXIMUM_DAILY_DRIVING_TIME_EXTENDED_MINUTES}
                        maximumWithoutExtensionMinutes={MAXIMUM_DAILY_DRIVING_TIME_MINUTES}
                        headerMessageId={'intl-msg:timed.widget.dailyDriving'}
                        textColor={'status-driving'}
                        progressBarColor={'progress-bar-status-driving'}
                    >
                        <span className={'margin-right-5'} />
                        <ClockCounterComponent
                            counter={displayItem[TableColumn.REMAINING_DRIVING_TODAY_CURRENT]?.numberOfItems}
                            maxCounter={MAX_NUMBER_OF_RESTING_EXCEED_PER_WEEK}
                            tooltipId={'intl-msg:timed.driving-time-table.numberOfTimes9HDailyDrivingTimeExceeding'}
                            activeColor={ActivityConfig[Activities.DRIVING].color}
                        />
                    </DrivingTimesCell>
                </div>
                <div className={'display-flex flex-column border rounded'}>
                    <DrivingTimesCell
                        testIdPrefix={'weekly-driving-time'}
                        currentMinutes={displayItem[TableColumn.DRIVING_WEEKLY_CURRENT]?.duration}
                        remainingMinutes={displayItem[TableColumn.REMAINING_WEEK_DRIVING_REMAINING]?.duration}
                        maximumMinutes={MAXIMUM_WEEKLY_DRIVING_MINUTES}
                        headerMessageId={'intl-msg:timed.widget.weeklyDriving'}
                        textColor={'status-driving'}
                        progressBarColor={'progress-bar-status-driving'}
                    />
                </div>
                <div className={'display-flex flex-column border rounded'}>
                    <DrivingTimesCell
                        testIdPrefix={'two-weekly-driving-time'}
                        currentMinutes={displayItem[TableColumn.DRIVING_TWO_WEEKLY_CURRENT]?.duration}
                        remainingMinutes={displayItem[TableColumn.REMAINING_DRIVING_TWO_WEEKLY_CURRENT]?.duration}
                        maximumMinutes={MAXIMUM_TWO_WEEKLY_DRIVING_MINUTES}
                        headerMessageId={'intl-msg:timed.widget.twoWeeklyDriving'}
                        textColor={'status-driving'}
                        progressBarColor={'progress-bar-status-driving'}
                    />
                </div>
            </div>
        </div>
    );
};
