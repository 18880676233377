import { JSX } from 'react';
import { RealTimeHeaderContainer } from './header/RealTimeHeader.container';
import { DrivingTimesTableContainer } from '../tables/drivingtimes/DrivingTimesTable.container';
import { connect } from 'react-redux';
import { getIsStateChangesDisplayed } from './redux/realtime.redux';
import { StateChangesTableContainer } from '../tables/statechanges/StateChangesTable.container';
import { TableCardsSortingHeaderContainer } from '../tables/header/TableCardsSortingHeader.container';
import { LoadingIndicator } from './LoadingIndicator';
import {
    getHasAccessDeniedFetchError,
    getIsDrivingTimesLoading,
    getIsStateChangesLoading,
} from '../api/redux/api.redux';
import ForbiddenState from '@rio-cloud/rio-uikit/ForbiddenState';
import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import { FormattedMessage } from 'react-intl';
import { getStateChangesDisplayItems } from '../tables/statechanges/stateChangesDataMappingService';
import { getFilteredAndSortedDrivingTimesDisplayItems } from '../tables/drivingtimes/drivingTimesDataPreparationService';
import { getSortBy } from './header/tablesettings/redux/tablesettings.redux';
import { UpsellingBuyButtonContainer } from '../upselling/UpsellingBuyButtonContainer';
import { VisibilityTrackerComponent } from '../../configuration/setup/VisbilityTracker.container';
import {
    createGoogleAnalyticsEvent,
    TrackingAction,
    TrackingCategory,
} from '../../configuration/setup/googleTagManager';
import { getIdsOfSelectedAssets } from '../tree/selectionService';
import { getAssetBookings } from '../data/redux/data.redux';
import { Product } from '../data/redux/types';
import { State } from '../../reducers';
import { getProductStatusOfSelectedAsset } from '../upselling/productStatusCalculation.service';
import { ProductStatus } from '../upselling/types';
import SortDirection from '@rio-cloud/rio-uikit/utils/SortUtils';

interface RealTimeProps {
    historicalViewSelected: boolean;
    isDrivingTimesLoading: boolean;
    isStateChangesLoading: boolean;
    noDataFoundForDrivingTimes: boolean;
    noDataFoundForActivities: boolean;
    hasAccessDeniedError: boolean;
    showUpselling: boolean;
}

const renderLoadingSpinner = (
    <div style={{ marginTop: '32px' }}>
        <LoadingIndicator />
    </div>
);

const renderStateChangeTable = (isStateChangesLoading: boolean, showUpselling: boolean): JSX.Element =>
    isStateChangesLoading ? (
        renderLoadingSpinner
    ) : (
        <>
            <StateChangesTableContainer />
            {showUpselling ? getUpsellingView() : null}
        </>
    );

const renderDrivingTimesTable = (isDrivingTimesLoading: boolean, showUpselling: boolean): JSX.Element =>
    isDrivingTimesLoading ? (
        renderLoadingSpinner
    ) : (
        <>
            <DrivingTimesTableContainer />
            {showUpselling ? getUpsellingView() : null}
        </>
    );

const getUpsellingView = (): JSX.Element => {
    return (
        <div>
            <UpsellingBuyButtonContainer />
            <VisibilityTrackerComponent
                googleAnalyticsEvent={createGoogleAnalyticsEvent(
                    TrackingCategory.ANY_TABLE,
                    TrackingAction.SHOW_UPSELLING_LINK_TO_MARKETPLACE
                )}
                componentIsVisible={true}
            />
        </div>
    );
};

const RealTime = (props: RealTimeProps): JSX.Element | null => {
    const {
        historicalViewSelected,
        isDrivingTimesLoading,
        isStateChangesLoading,
        noDataFoundForDrivingTimes,
        noDataFoundForActivities,
        hasAccessDeniedError,
        showUpselling,
    } = props;

    if (hasAccessDeniedError) {
        return (
            <div data-testid={'realtimeForbiddenState'}>
                <ForbiddenState
                    headline={<FormattedMessage id={'intl-msg:timed.common.accessDenied.headline'} />}
                    message={<FormattedMessage id={'intl-msg:timed.common.accessDenied.message'} />}
                />
            </div>
        );
    }

    const showNoDataFound =
        (!historicalViewSelected && !isDrivingTimesLoading && noDataFoundForDrivingTimes) ||
        (historicalViewSelected && !isStateChangesLoading && noDataFoundForActivities);

    if (showNoDataFound) {
        return (
            <div className={'RealTime form-group'} key={'RealTime-site'}>
                <RealTimeHeaderContainer />

                <NotFoundState
                    headline={<FormattedMessage id={'intl-msg:timed.common.noData.header'} />}
                    message={<FormattedMessage id={'intl-msg:timed.common.noData.message'} />}
                    fullWidth={true}
                    outerClassName={'margin-bottom-20'}
                />
                {showUpselling ? getUpsellingView() : null}
            </div>
        );
    } else {
        return (
            <div className={'RealTime form-group'} key={'RealTime-site'}>
                <RealTimeHeaderContainer />

                <TableCardsSortingHeaderContainer />
                {historicalViewSelected
                    ? renderStateChangeTable(isStateChangesLoading, showUpselling)
                    : renderDrivingTimesTable(isDrivingTimesLoading, showUpselling)}
            </div>
        );
    }
};

const getShowUpselling = (state: State): boolean => {
    const isSingleAssetSelected = (selectedAssetIds: string[]) => selectedAssetIds.length === 1;
    const idsOfSelectedAssets = getIdsOfSelectedAssets(state);

    if (!isSingleAssetSelected(idsOfSelectedAssets)) {
        return false;
    }
    const bookingStatus = getProductStatusOfSelectedAsset(
        getAssetBookings(state),
        idsOfSelectedAssets[0],
        Product.TIMED
    );
    return bookingStatus === ProductStatus.NOT_BOOKED;
};

const mapStateToProps = (state: State): RealTimeProps => ({
    historicalViewSelected: getIsStateChangesDisplayed(state),
    isDrivingTimesLoading: getIsDrivingTimesLoading(state),
    isStateChangesLoading: getIsStateChangesLoading(state),
    noDataFoundForDrivingTimes:
        getFilteredAndSortedDrivingTimesDisplayItems(state, getSortBy(state), SortDirection.ASCENDING).length === 0,
    noDataFoundForActivities: getStateChangesDisplayItems(state).length === 0,
    hasAccessDeniedError: getHasAccessDeniedFetchError(state),
    showUpselling: getShowUpselling(state),
});

export const RealTimeContainer = connect(mapStateToProps)(RealTime);
