import { JSX } from 'react';
import { connect } from 'react-redux';
import { getCurrentColumns } from '../drivingtimes/columnService';
import { State } from '../../../reducers';
import { TableColumn } from '../../enums/TableColumn';
import { getStateChangeColumns } from '../statechanges/stateChangesColumnService';

interface TableColGroupsProps {
    columns: TableColumn[];
}

const TableColGroups = (props: TableColGroupsProps): JSX.Element => {
    const { columns } = props;
    return (
        <colgroup>
            {columns.map((column) => (
                <col key={column} />
            ))}
        </colgroup>
    );
};

const mapStateToProps = (state: State): TableColGroupsProps => ({
    columns: getCurrentColumns(state),
});

const mapStateToStateChangesProps = (): TableColGroupsProps => ({
    columns: getStateChangeColumns(),
});

export const DrivingTimesTableColGroupsContainer = connect(mapStateToProps)(TableColGroups);
export const StateChangesTableColGroupsContainer = connect(mapStateToStateChangesProps)(TableColGroups);
