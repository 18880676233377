/* tslint:disable:jsx-no-lambda */
import { JSX, ReactNode, Component } from 'react';

import ListMenu from '@rio-cloud/rio-uikit/ListMenu';
import SplitDialog from '@rio-cloud/rio-uikit/SplitDialog';
import { FormattedMessage } from 'react-intl';

import { ActivitiesExplanation } from './glossary/ActivitiesExplanation';
import { TableSettingsExplanation } from './glossary/TableSettingsExplanation';
import { DataAvailabilityExplanation } from './glossary/DataAvailabilityExplanation';
import { WorkingtimesExplanation } from './glossary/WorkingtimesExplanation';
import { ListMenuNavItem } from '@rio-cloud/rio-uikit/components/listMenu/ListMenuGroup';

const header = <FormattedMessage id={'intl-msg:timed.glossary.help'} />;

const getItem = (shownContent: string, setShownContent: (content: string) => void, key: string): ListMenuNavItem => ({
    key,
    item: (
        // eslint-disable-next-line
        <a
            key={`help-navitem-${key}`}
            href={'javascript:void(0)'} // eslint-disable-line no-script-url
            onClick={(): void => setShownContent(key)}
            className={shownContent === key ? 'active' : ''}
        >
            <FormattedMessage id={`intl-msg:timed.glossary.${key}`} />
        </a>
    ),
});

const getMenuItems = (
    shownContent: string,
    setShownContent: (content: string) => void
): { group: JSX.Element; navItems: ListMenuNavItem[] }[] => [
    {
        group: <FormattedMessage id={'intl-msg:timed.common.subModuleName.timed'} />,
        navItems: [
            getItem(shownContent, setShownContent, 'activities'),
            getItem(shownContent, setShownContent, 'tableSettings'),
            getItem(shownContent, setShownContent, 'dataAvailability'),
            getItem(shownContent, setShownContent, 'workingTimes'),
        ],
    },
];

const getBody = (shownContent: ReactNode): JSX.Element => {
    switch (shownContent) {
        case 'activities':
            return <ActivitiesExplanation />;
        case 'tableSettings':
            return <TableSettingsExplanation />;
        case 'dataAvailability':
            return <DataAvailabilityExplanation />;
        case 'workingTimes':
            return <WorkingtimesExplanation />;
        default:
            return <div />;
    }
};

interface GlossaryDialogProps {
    toggleDialog: () => void;
    show: boolean;
}

interface GlossaryDialogState {
    shownContent: string;
}

export class GlossaryDialog extends Component<GlossaryDialogProps, GlossaryDialogState> {
    constructor(props: GlossaryDialogProps) {
        super(props);

        this.state = {
            shownContent: 'timed.activities',
        };

        this.setShownContent = this.setShownContent.bind(this);
    }

    setShownContent(content: string): void {
        this.setState({ shownContent: content });
    }

    render(): JSX.Element {
        return (
            <SplitDialog
                show={this.props.show}
                title={header}
                leftContent={
                    <ListMenu
                        key={this.state.shownContent}
                        menuItems={getMenuItems(this.state.shownContent, this.setShownContent)}
                        groupClassName={'padding-left-0 GlossaryListMenu'}
                    />
                }
                rightContent={getBody(this.state.shownContent)}
                bsSize={'lg'}
                onClose={this.props.toggleDialog}
            />
        );
    }
}
