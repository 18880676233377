import { JSX } from 'react';
import { StatusDuration } from './types';
import { TooltipWrapper } from '../../onboarding/TooltipWrapper';
import { EmptyDataIconWithTooltip } from '../elements/EmptyDataIcon';
import Activity from '@rio-cloud/rio-uikit/Activity';
import { formatDuration } from '../../formatting/TimeFormatting';
import { ActivityConfig } from '../../enums/Activities';

interface StatusDurationCellProps {
    value?: StatusDuration;
}

export const StatusDurationCell = (props: StatusDurationCellProps): JSX.Element => {
    const { value } = props;

    if (!value) {
        return <EmptyDataIconWithTooltip />;
    }

    const { translationId, uikitActivity } = ActivityConfig[value.status];
    return (
        <div className={'cards-justify-content'}>
            <TooltipWrapper tooltipId={translationId}>
                <div className={'display-inline-block'}>
                    <Activity activity={uikitActivity} duration={formatDuration(value.duration)} />
                </div>
            </TooltipWrapper>
        </div>
    );
};
