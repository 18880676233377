import { JSX } from 'react';
import RioglyphIcon from '../../wrapper/RioglyphIcon';
import { TooltipWrapper } from '../../onboarding/TooltipWrapper';
import { INACTIVE_COLOR } from '../../enums/Activities';

export const EmptyDataIcon = (): JSX.Element => (
    <RioglyphIcon color={INACTIVE_COLOR} type={'question-sign'} size={'larger'} />
);

export const EmptyDataIconWithTooltip = (): JSX.Element => (
    <TooltipWrapper tooltipId={'intl-msg:timed.common.emptyDataIconMessage'}>
        <span>
            <RioglyphIcon color={INACTIVE_COLOR} type={'question-sign'} size={'larger'} />
        </span>
    </TooltipWrapper>
);

export const EmptyDataMultiManning = (): JSX.Element => (
    <TooltipWrapper tooltipId={'intl-msg:timed.sidebar.multiManningEmptyDataIconMessage'}>
        <div>
            <RioglyphIcon color={INACTIVE_COLOR} type={'question-sign'} size={'larger'} />
        </div>
    </TooltipWrapper>
);

export const EmptyDataUnknownVehicle = (): JSX.Element => (
    <TooltipWrapper tooltipId={'intl-msg:timed.driving-time-table.noPermissionToSeeVehicle'}>
        <span>
            <RioglyphIcon color={INACTIVE_COLOR} type={'question-sign'} size={'larger'} />
        </span>
    </TooltipWrapper>
);
