import { JSX } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { connect } from 'react-redux';
import Position from '@rio-cloud/rio-uikit/Position';
import {
    getLatestAddress,
    getLatestLatitude,
    getLatestLongitude,
    getTimestampOfLatestPosition,
} from '../redux/detailsSidebar.redux';
import { State } from '../../../reducers';

const displayPositionInfo = (latitude: number, longitude: number, address: string | undefined): JSX.Element => (
    <div className={'display-flex flex-column'}>
        <label className={'margin-0'}>
            <FormattedMessage id={'intl-msg:timed.sidebar.currentMapPosition'} />{' '}
        </label>
        <Position latitude={latitude} longitude={longitude} address={address !== null ? address : undefined} />
    </div>
);

const dateProps: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
};

// noinspection HtmlUnknownAttribute
const displayTimeInfo = (timestamp: string | undefined): JSX.Element => (
    <div className={'display-flex flex-column align-items-end'} data-test-id="map header">
        <label className={'margin-0'}>
            <FormattedMessage id={'intl-msg:timed.common.timestamp'} />{' '}
        </label>
        <span>
            <FormattedDate value={timestamp} {...dateProps} /> {<FormattedTime value={timestamp} />}
        </span>
    </div>
);

interface MapHeaderProps {
    latitude?: number;
    longitude?: number;
    address?: string;
    timestamp?: string;
}

const MapHeader = (props: MapHeaderProps) => {
    const { latitude, longitude, timestamp, address } = props;
    if (latitude && longitude) {
        return (
            <div className={'display-flex justify-content-between'}>
                {displayPositionInfo(latitude, longitude, address)}
                {timestamp && displayTimeInfo(timestamp)}
            </div>
        );
    } else {
        return null;
    }
};

const mapStateToProps = (state: State): MapHeaderProps => {
    return {
        latitude: getLatestLatitude(state),
        longitude: getLatestLongitude(state),
        address: getLatestAddress(state),
        timestamp: getTimestampOfLatestPosition(state),
    };
};

export const MapHeaderContainer = connect(mapStateToProps)(MapHeader);
