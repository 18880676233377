import { JSX, ReactNode } from 'react';

import StatusBar from '@rio-cloud/rio-uikit/StatusBar';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { InfringementLevel } from '../../enums/InfringementLevel';

import { TimerValueComponent } from './TimerValueComponent';

interface ProgressPart {
    percentage: number;
    color: string;
    tooltipId?: string;
}

interface TimeScaleBarComponentProps {
    progressParts: ProgressPart[];
    numberValue: number;
    textColor: string;
    infringementLevel?: InfringementLevel;
    children?: ReactNode;
}

export const DANGER_COLOR = 'danger';
export const WARNING_COLOR = 'warning';

export const calculateTextColor = (defaultColor: string, level?: InfringementLevel): string => {
    switch (level) {
        case InfringementLevel.WARN:
            return WARNING_COLOR;
        case InfringementLevel.MINOR:
        case InfringementLevel.ALERT:
        case InfringementLevel.SERIOUS:
        case InfringementLevel.VERY_SERIOUS:
        case InfringementLevel.MOST_SERIOUS:
            return DANGER_COLOR;
        case InfringementLevel.INFO:
        default:
            return defaultColor;
    }
};

const TimeScaleBarBase = (props: TimeScaleBarComponentProps & WrappedComponentProps): JSX.Element => {
    const { progressParts, numberValue, intl, infringementLevel, textColor, children = null } = props;

    const calculatedTextColor = calculateTextColor(textColor, infringementLevel);
    const value = (
        <div className={'display-flex'}>
            <TimerValueComponent minutes={numberValue} color={calculatedTextColor} size={'16'} />
            {children}
        </div>
    );
    return (
        <StatusBar
            label={{
                value,
                alignment: 'top',
                color: 'text-color-status-resting',
            }}
            progress={progressParts.map((progressPart) => ({
                percentage: progressPart.percentage,
                color: `bg-${progressPart.color}`,
                tooltip: progressPart.tooltipId ? intl.formatMessage({ id: progressPart.tooltipId }) : '',
            }))}
            className="MyProgress"
        />
    );
};

export const TimeScaleBarComponent = injectIntl(TimeScaleBarBase);
