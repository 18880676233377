import { JSX } from 'react';
const ONE_HOUR_MINUTES = 60;
const SINGLE_DIGIT_NUMBER_COUNT = 9;

export const formatDuration = (minutes?: number): string | undefined => {
    if (typeof minutes !== 'number') {
        return undefined;
    }
    const roundedMinutes = Math.round(minutes);
    if (minutes < 0) {
        return '0:00 h';
    }
    const hours = Math.floor(roundedMinutes / ONE_HOUR_MINUTES);
    const minutesLeft = roundedMinutes % ONE_HOUR_MINUTES;
    const hoursString = hours === 0 ? '0' : hours;
    const minutesString = minutesLeft <= SINGLE_DIGIT_NUMBER_COUNT ? `0${minutesLeft}` : minutesLeft;

    return `${hoursString}:${minutesString} h`;
};

interface DurationComponentProps {
    minutes: number;
    displayColor: string;
    displaySize?: string;
}

export const DurationComponent = (props: DurationComponentProps): JSX.Element => {
    const { minutes, displayColor, displaySize = '14' } = props;

    return <strong className={`text-color-${displayColor} text-size-${displaySize}`}>{formatDuration(minutes)}</strong>;
};
