import { JSX } from 'react';
import { FormattedMessage } from 'react-intl';
import FleetboardDataAvailability from './assets/fleetboardDataAvailability.png';

const pictureStyle = 'margin-10 width-90pct';

export const DataAvailabilityExplanation = (): JSX.Element => (
    <div className="text-center">
        <div className="margin-bottom-15">
            <FormattedMessage id={'intl-msg:timed.glossary.dataAvailability.text'} />
        </div>
        <div className="text-size-h3 text-medium margin-bottom-15">
            <FormattedMessage id={'intl-msg:timed.glossary.dataAvailability.fleetboard.header'} />
        </div>
        <img src={FleetboardDataAvailability} alt={'FleetboardDataAvailability'} className={`${pictureStyle}`} />
    </div>
);
