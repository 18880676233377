import { JSX } from 'react';
import { EmptyDataIconWithTooltip } from '../elements/EmptyDataIcon';
import { TimeScaleBarComponent } from '../elements/TimeScaleBarComponent';
import { DurationAndItems } from './types';
import { Activities, ActivityConfig, INACTIVE_COLOR } from '../../enums/Activities';

interface CurrentRestingCellProps {
    value?: DurationAndItems;
}

const MAXIMUM_RESTING = 45;

export const CurrentRestingCell = (props: CurrentRestingCellProps): JSX.Element => {
    const { value } = props;

    if (!value || value.duration === undefined) {
        return <EmptyDataIconWithTooltip />;
    }

    const percentageOfTime = (100 * value.duration) / MAXIMUM_RESTING;

    const progressParts = [
        {
            percentage: percentageOfTime,
            color: ActivityConfig[Activities.RESTING].color,
            tooltipId: 'intl-msg:timed.driving-time-table.cumulativeBreakTime',
        },
        {
            percentage: 100 - percentageOfTime,
            color: INACTIVE_COLOR,
            tooltipId: 'intl-msg:timed.driving-time-table.45Minutes',
        },
    ];

    return (
        <TimeScaleBarComponent
            numberValue={value.duration}
            progressParts={progressParts}
            infringementLevel={value?.level}
            textColor={ActivityConfig[Activities.RESTING].color}
        />
    );
};
