import { JSX } from 'react';
import { EmptyDataIconWithTooltip } from '../elements/EmptyDataIcon';
import { TimeScaleBarComponent } from '../elements/TimeScaleBarComponent';
import { DurationAndItems } from './types';
import { Activities, ActivityConfig, INACTIVE_COLOR } from '../../enums/Activities';
import {
    MAX_NUMBER_OF_RESTING_EXCEED_PER_WEEK,
    MAXIMUM_DAILY_DRIVING_TIME_EXTENDED_MINUTES,
    MAXIMUM_DAILY_DRIVING_TIME_MINUTES,
} from '../../legalDrivingRestrictions';
import { ClockCounterComponent } from '../elements/ClockCounterComponent';

interface CurrentDrivingTodayCellProps {
    value?: DurationAndItems;
}

export const CurrentDrivingTodayCell = (props: CurrentDrivingTodayCellProps): JSX.Element => {
    const { value } = props;

    if (!value || value.duration === undefined) {
        return <EmptyDataIconWithTooltip />;
    }

    const percentageOfTime = (100 * value.duration) / MAXIMUM_DAILY_DRIVING_TIME_EXTENDED_MINUTES;
    const percentageOf9Hours = (100 * MAXIMUM_DAILY_DRIVING_TIME_MINUTES) / MAXIMUM_DAILY_DRIVING_TIME_EXTENDED_MINUTES;

    const progressParts = [
        {
            percentage: percentageOfTime,
            color: ActivityConfig[Activities.DRIVING].color,
            tooltipId: 'intl-msg:timed.driving-time-table.dailyDrivingTime',
        },
        {
            percentage: percentageOf9Hours - percentageOfTime,
            color: INACTIVE_COLOR,
            tooltipId: 'intl-msg:timed.driving-time-table.9HoursDrivingTime',
        },
        {
            percentage: 100 - percentageOf9Hours,
            color: 'lighter',
            tooltipId: 'intl-msg:timed.driving-time-table.10HoursDrivingTime',
        },
    ];

    return (
        <TimeScaleBarComponent
            numberValue={value.duration}
            progressParts={progressParts}
            infringementLevel={value?.level}
            textColor={ActivityConfig[Activities.DRIVING].color}
        >
            <span className={'margin-right-5'} />
            <ClockCounterComponent
                counter={value.numberOfItems}
                maxCounter={MAX_NUMBER_OF_RESTING_EXCEED_PER_WEEK}
                activeColor={ActivityConfig[Activities.DRIVING].color}
            />
        </TimeScaleBarComponent>
    );
};
