import { Component, JSX } from 'react';
import SteppedProgressBar, { SteppedProgressBarLabel } from '@rio-cloud/rio-uikit/SteppedProgressBar';

interface ExplanationProps {
    steps: SteppedProgressBarLabel[];
    getExplanation: (selectedStep: number) => JSX.Element;
}

interface ExplanationState {
    selectedStep: number;
}

export class Explanation extends Component<ExplanationProps, ExplanationState> {
    constructor(props: ExplanationProps) {
        super(props);

        this.state = {
            selectedStep: 0,
        };

        this.onSelectedChanged = this.onSelectedChanged.bind(this);
        this.switchLeft = this.switchLeft.bind(this);
        this.switchRight = this.switchRight.bind(this);
    }

    onSelectedChanged(selected: number): void {
        this.setState({ selectedStep: selected });
    }

    getSwitchLeftEnabled(): boolean {
        return this.state.selectedStep > 0;
    }

    getSwitchRightEnabled(): boolean {
        return this.state.selectedStep < this.props.steps.length - 1;
    }

    switchLeft(): void {
        if (this.getSwitchLeftEnabled()) {
            this.setState({ selectedStep: this.state.selectedStep - 1 });
        }
    }

    switchRight(): void {
        if (this.getSwitchRightEnabled()) {
            this.setState({ selectedStep: this.state.selectedStep + 1 });
        }
    }

    render(): JSX.Element {
        const buttonBaseClasses = 'btn btn-link btn-icon-only text-size-20';
        return (
            <div className={'row equal-height'}>
                <div className={'col-sm-1'}>
                    <div className={'position-absolute top-50pct'}>
                        <button
                            className={`${buttonBaseClasses} ${this.getSwitchLeftEnabled() ? '' : 'disabled'}`}
                            onClick={this.switchLeft}
                        >
                            <span className={'rioglyph rioglyph-chevron-left'} />
                        </button>
                    </div>
                </div>
                <div className={'col-sm-10'}>
                    <div className={'text-center padding-left-20 padding-right-20'}>
                        <SteppedProgressBar
                            selectedStepNumber={this.state.selectedStep}
                            onSelectedChanged={this.onSelectedChanged}
                            labels={this.props.steps}
                            disableFollowingPages={this.props.steps.length}
                        />
                        {this.props.getExplanation(this.state.selectedStep)}
                    </div>
                </div>
                <div className={'col-sm-1'}>
                    <div className={'position-absolute top-50pct'}>
                        <button
                            className={`${buttonBaseClasses} ${this.getSwitchRightEnabled() ? '' : 'disabled'}`}
                            onClick={this.switchRight}
                        >
                            <span className={'rioglyph rioglyph-chevron-right'} />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
