import { JSX } from 'react';
import { TrackingAction, trackingAttributes, TrackingCategory } from '../../../configuration/setup/googleTagManager';
import { FormattedMessage } from 'react-intl';

const trackingClickAttributes = trackingAttributes(
    TrackingCategory.WIDGET_VIEW,
    TrackingAction.CLICK_ON_SHOW_OUTDATED_DATA_BUTTON
);
export const ShowOutdatedDataButton = ({ onClick }: { onClick: () => void }): JSX.Element => (
    <>
        <div className="text-size-16 text-color-dark">
            <FormattedMessage id={'intl-msg:timed.widget.outdated'} />
        </div>
        <div className="padding-top-15">
            <button
                className="btn btn-primary btn-default"
                {...trackingClickAttributes}
                onClick={onClick}
                data-testid={'ShowOutdatedDataButton'}
            >
                <FormattedMessage id={'intl-msg:timed.widget.showOutdatedData'} />
            </button>
        </div>
    </>
);
