import { JSX, ReactNode } from 'react';

import OnboardingTip from '@rio-cloud/rio-uikit/OnboardingTip';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Tips } from './types';
import { Dispatch } from 'redux';
import { getShowOnboardingTip, onboardingActions } from './redux/oboarding.redux';
import { PayloadAction } from '@reduxjs/toolkit';
import { State } from '../../reducers';
import { Placement } from '@rio-cloud/rio-uikit/values/Placement';

interface OnboardingTipWrapperStateProps {
    showOnboardingTip: (tip: Tips) => boolean;
}

interface OnboardingTipWrapperDispatchProps {
    hideOnboardingTip: (tip: Tips) => PayloadAction<{ type: Tips; value: boolean }>;
}

interface OnboardingTipWrapperProps {
    tipType: Tips;
    titleTranslationId: string;
    contentTranslationId: string;
    children: ReactNode;
    className?: string;
    placement?: Placement;
}

export const OnboardingTipWrapper = (
    props: OnboardingTipWrapperStateProps &
        OnboardingTipWrapperDispatchProps &
        OnboardingTipWrapperProps &
        WrappedComponentProps
): JSX.Element => {
    const {
        children,
        intl,
        tipType,
        titleTranslationId,
        contentTranslationId,
        hideOnboardingTip,
        showOnboardingTip,
        className = 'max-width-200',
        placement = 'bottom',
    } = props;

    const onHide = (): PayloadAction<{ type: Tips; value: boolean }> => hideOnboardingTip(tipType);

    return (
        <OnboardingTip
            show={showOnboardingTip(tipType)}
            placement={placement}
            title={intl.formatMessage({ id: titleTranslationId })}
            content={intl.formatMessage({ id: contentTranslationId })}
            className={className}
            onHide={onHide}
        >
            {children}
        </OnboardingTip>
    );
};

const mapStateToProps = (state: State): OnboardingTipWrapperStateProps => ({
    showOnboardingTip: (tip: Tips): boolean => getShowOnboardingTip(state, tip),
});

const mapDispatchToProps = (dispatch: Dispatch): OnboardingTipWrapperDispatchProps => ({
    hideOnboardingTip: (tip: Tips): PayloadAction<{ type: Tips; value: boolean }> =>
        dispatch(onboardingActions.setShowOnboardingTip({ type: tip, value: false })),
});

export const ConnectedOnboardingTip = connect(mapStateToProps, mapDispatchToProps)(injectIntl(OnboardingTipWrapper));
