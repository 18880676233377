import { useState, JSX } from 'react';
import { useSelector } from 'react-redux';
import IframeResizer from 'iframe-resizer-react';
import Button from '@rio-cloud/rio-uikit/Button';
import Dialog from '@rio-cloud/rio-uikit/Dialog';

import { getLocale } from '../../configuration/lang/selectors';
import { TimedBottomSheet } from './TimedBottomSheet';
import { ImageLoader } from './ImageLoader';
import {
    createGoogleAnalyticsEvent,
    dataLayerPush,
    TrackingAction,
    TrackingCategory,
} from '../../configuration/setup/googleTagManager';
import FeedbackImage from './feedback.jpg';
import { getUserAccount } from '../../configuration/login/selectors';

/*
 * Example for how to use the BottomSheet
 *
 * Note:
 * Don't forget to cleanup the localStorage for the user when removing a feature
 * by setting "cleanupLocalStorage" to "true" and deploy it like this.
 * The cleanup will remove the localStorage flag on mount and will not show the bottom sheet.
 */

const SHOW_AFTER_IN_MS = 10_000; // 10 seconds
const HIDE_AFTER_IN_MS = 60_000; // 1 minute
const EXCLUDED_ACCOUNT_IDS = [
    '47bbd84b-b46d-4bdc-a9d7-eb36b70358ec', // RIO Demo account
];

export const BottomSheetSurvey = (): JSX.Element | null => {
    const [isDismissed, setIsDismissed] = useState(false);
    const [showPromotionDialog, setShowPromotionDialog] = useState(false);
    const [acceptToGiveFeedback, setAcceptToGiveFeedback] = useState(false);

    const locale = useSelector(getLocale);
    const account = useSelector(getUserAccount);
    if (locale !== 'de-DE') {
        return null;
    }

    if (EXCLUDED_ACCOUNT_IDS.includes(account || '')) {
        return null;
    }

    const handleCloseBottomSheet = () => {
        dataLayerPush(
            createGoogleAnalyticsEvent(
                TrackingCategory.DRIVING_TIMES_VIEW,
                TrackingAction.CLICK_ON_BOTTOMSHEET,
                'Closed BottomSheetSurvey BottomSheet via close button'
            )
        );
    };

    const handleOpenDialog = () => {
        setShowPromotionDialog(true);
        dataLayerPush(
            createGoogleAnalyticsEvent(
                TrackingCategory.DRIVING_TIMES_VIEW,
                TrackingAction.CLICK_ON_BOTTOMSHEET,
                'Read more button clicked in BottomSheetSurvey BottomSheet'
            )
        );
    };

    const handleCloseDialog = () => {
        setShowPromotionDialog(false);
        setIsDismissed(true);
        dataLayerPush(
            createGoogleAnalyticsEvent(
                TrackingCategory.DRIVING_TIMES_VIEW,
                TrackingAction.CLICK_ON_BOTTOMSHEET_DIALOG,
                'Closed BottomSheetSurvey Dialog via close button or esc'
            )
        );
    };

    const handleAcceptGivingFeedback = () => {
        setAcceptToGiveFeedback(true);
        dataLayerPush(
            createGoogleAnalyticsEvent(
                TrackingCategory.DRIVING_TIMES_VIEW,
                TrackingAction.CLICK_ON_BOTTOMSHEET_DIALOG,
                'User clicked on BottomSheetSurvey Dialog footer button to see the actual survey.'
            )
        );
    };

    const handleCloseSurvey = () => {
        setShowPromotionDialog(false);
        setIsDismissed(true);
        dataLayerPush(
            createGoogleAnalyticsEvent(
                TrackingCategory.DRIVING_TIMES_VIEW,
                TrackingAction.CLICK_ON_BOTTOMSHEET_DIALOG,
                'User closed the BottomSheetSurvey Dialog via footer button.'
            )
        );
    };

    const bodyStyle = acceptToGiveFeedback
        ? {
              minHeight: '520px',
          }
        : {};

    return (
        <>
            <TimedBottomSheet
                dismissed={isDismissed}
                featureName="BottomSheetSurvey"
                showAfter={SHOW_AFTER_IN_MS}
                hideAfter={HIDE_AFTER_IN_MS}
                className="max-width-400"
                onClose={handleCloseBottomSheet}
                cleanupLocalStorage={false}
            >
                <div className="display-flex flex-column align-items-center gap-20">
                    <div className="display-flex flex-column">
                        <div className="text-size-16 text-medium margin-bottom-10">
                            Ihre Verbesserungsvorschläge werden belohnt!
                        </div>
                        <div>
                            Wir möchten unseren Dienst <strong>Timed</strong> an Ihre Bedürfnisse anpassen. Geben Sie
                            uns Feedback und erhalten Sie ein RIO-Überraschungspaket. Neugierig geworden?
                        </div>
                    </div>
                    <div>
                        <Button className="min-width-150" bsStyle={Button.PRIMARY} onClick={handleOpenDialog}>
                            Mehr erfahren...
                        </Button>
                    </div>
                </div>
            </TimedBottomSheet>

            <Dialog
                bsSize="sm"
                show={showPromotionDialog}
                title={<div>Ihr Feedback wird belohnt</div>}
                body={
                    <div style={bodyStyle}>
                        {acceptToGiveFeedback && (
                            <IframeResizer
                                title="Feedback Formular"
                                src="https://cloud.news.rio.cloud/landingpages?project=Timed"
                            />
                        )}
                        {!acceptToGiveFeedback && (
                            <div className="section-content-container-body text-size-14">
                                <p className="margin-top-0">
                                    <strong>
                                        Wir bauen für Sie um und Ihre Verbesserungsvorschläge liegen uns am Herzen.
                                    </strong>
                                </p>
                                <ul className="icon-list margin-bottom-0">
                                    <li>
                                        <span className="rioglyph rioglyph-ok-sign text-color-success" />
                                        <span>
                                            Kleines Interview zu Ihren Erfahrungen und Verbesserungsvorschlägen
                                            hinsichtlich unseres Dienstes “Timed”
                                        </span>
                                    </li>
                                    <li>
                                        <span className="rioglyph rioglyph-ok-sign text-color-success" />
                                        <span>
                                            Teilen Sie uns Ihre Wünsche mit und helfen Sie uns so, die optimalste Lösung
                                            für Ihren Arbeitsalltag zu entwickeln!
                                        </span>
                                    </li>
                                    <li>
                                        <span className="rioglyph rioglyph-ok-sign text-color-success" />
                                        <span>
                                            Jede aktive Teilnahme wird mit einem kleinen RIO-Überraschungs-Paket belohnt
                                        </span>
                                    </li>
                                </ul>
                                <div className="display-flex">
                                    <div className="text-bold min-width-60">Dauer:</div>
                                    <div>ca. 25 Minuten</div>
                                </div>
                                <div className="display-flex">
                                    <div className="text-bold min-width-60">Ort:</div>
                                    <div>Online via Microsoft Teams Meeting (ohne Login)</div>
                                </div>
                                <ImageLoader
                                    className="margin-top-15 border rounded"
                                    src={FeedbackImage}
                                    title="Feedback image"
                                />
                            </div>
                        )}
                    </div>
                }
                footer={
                    <>
                        {acceptToGiveFeedback && (
                            <div className="display-flex justify-content-end btn-toolbar">
                                <Button onClick={handleCloseSurvey}>Schließen</Button>
                            </div>
                        )}
                        {!acceptToGiveFeedback && (
                            <div className="display-flex justify-content-end btn-toolbar">
                                <Button bsStyle="primary" onClick={handleAcceptGivingFeedback}>
                                    Ja, ich möchte Feedback geben
                                </Button>
                            </div>
                        )}
                    </>
                }
                onClose={handleCloseDialog}
                onEsc={handleCloseDialog}
            />
        </>
    );
};
