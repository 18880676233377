import { JSX } from 'react';
// import PropTypes from 'prop-types';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { FormattedMessage } from 'react-intl';
import { sumBy } from 'lodash';

import RioglyphIcon from '../wrapper/RioglyphIcon';

import { InlineTimeStamp } from '../detailssidebar/elements/InlineTimestamp';
import moment, { Moment } from 'moment';
import { Activities, ActivityConfig, INACTIVE_COLOR } from '../enums/Activities';
import { TooltipWrapper } from '../onboarding/TooltipWrapper';
import { convertToStateActivity } from '../tables/statechanges/stateChangesDataMappingService';
import { renderChart, renderChartNegative } from '../tables/cells/StatusChangesCell';
import { ApiStateChangeItem, StateChange } from '../api/types';
import { ShiftTimes } from '../detailssidebar/types';
import { DurationComponent } from '../formatting/TimeFormatting';
import { StateActivity } from '../tables/statechanges/types';
import { EmptyDataIcon } from '../tables/elements/EmptyDataIcon';
import FormattedTimeStamp from '../formatting/FormattedTimeStamp';
import { TimelineComponent } from '../tables/cells/TimeLine.container';
import { getStateStyle, TimeBlock } from '../tables/elements/TimeBlock';

export interface StateChangesDetailsDialogProps {
    endRange: Moment;
    historicalViewSelectionState: DetailsDisplayStateChange | undefined;
    onClose: () => void;
    receivedTimestamp: Moment | undefined;
    startRange: Moment;
}

export interface DetailsDisplayStateChange extends StateChange {
    driverName?: string;
    vehicleName?: string;
    shiftTimes?: ShiftTimes;
}

const getTimeFrameText = (startRange: Moment, endRange: Moment): JSX.Element => (
    <span>
        <FormattedTimeStamp date={startRange} displayYear={true} />
        {' - '}
        <FormattedTimeStamp date={endRange} displayYear={true} />
    </span>
);

const getSumMinutesOfActivities = (activities: StateActivity[], rangeStart: moment.Moment, rangeEnd: moment.Moment) =>
    Math.round(
        sumBy(activities, (activity) => {
            let normalisedActivityEnd;
            if (activity.to.isAfter(rangeEnd)) {
                normalisedActivityEnd = rangeEnd;
            } else {
                normalisedActivityEnd = activity.to.isAfter(rangeStart) ? activity.to : rangeStart;
            }

            let normalisedActivityStart;
            if (activity.from.isAfter(rangeEnd)) {
                normalisedActivityStart = rangeEnd;
            } else {
                normalisedActivityStart = activity.from.isAfter(rangeStart) ? activity.from : rangeStart;
            }

            return normalisedActivityEnd.diff(normalisedActivityStart, 'minutes');
        })
    );

interface ActivityRowProps {
    activityType?: string;
    activities: ApiStateChangeItem[];
    rangeStart: Moment;
    rangeEnd: Moment;
    renderNegativeChart?: boolean;
}

const ActivityRow = ({
    activityType,
    activities,
    rangeStart,
    rangeEnd,
    renderNegativeChart = false,
}: ActivityRowProps): JSX.Element => {
    let displayConfig;
    if (activityType) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        displayConfig = ActivityConfig[activityType];
    } else {
        displayConfig = renderNegativeChart
            ? {
                  type: 'question-sign',
                  color: 'gray',
                  translationId: 'intl-msg:timed.dialog.noSignalReceived',
              }
            : {
                  color: 'darker',
                  translationId: 'intl-msg:glossary.activity.available',
              };
    }

    const stateActivities = (
        activityType
            ? activities.filter((item: ApiStateChangeItem) => item.working_state.state === activityType)
            : activities
    )
        .map(convertToStateActivity)
        .filter((item) => item !== undefined) as StateActivity[];

    const summedActivityDuration = getSumMinutesOfActivities(stateActivities, rangeStart, rangeEnd);

    return (
        <div className={'display-flex padding-top-20 padding-bottom-20 align-items-center text-size-14'}>
            <div className={'width-200 text-right'}>
                {!activityType && !renderNegativeChart ? (
                    <div className={'display-inline-block'}>
                        <FormattedMessage id={'intl-msg:timed.common.all'} />
                    </div>
                ) : (
                    <TooltipWrapper tooltipId={displayConfig.translationId}>
                        <div className={'display-inline-block'}>
                            <RioglyphIcon {...displayConfig} size={'20'} />
                        </div>
                    </TooltipWrapper>
                )}
                <span className={'padding-left-10'}>
                    <span className={`badge bg-${displayConfig.color} text-color-white`}>
                        <DurationComponent
                            minutes={
                                renderNegativeChart
                                    ? rangeEnd.diff(rangeStart, 'minutes') - summedActivityDuration
                                    : summedActivityDuration
                            }
                            displayColor={'white'}
                            displaySize={'14'}
                        />
                        <sup>{'*'}</sup>
                    </span>
                </span>
            </div>
            <div className={'width-100pct overflow-hidden margin-left-15 margin-right-10'}>
                {renderNegativeChart
                    ? renderChartNegative(stateActivities, rangeStart, rangeEnd)
                    : renderChart(stateActivities, rangeStart, rangeEnd)}
            </div>
        </div>
    );
};

interface ShiftTimeStartLabelProps {
    shiftActivity?: StateActivity;
    rangeStart: moment.Moment;
    rangeEnd: moment.Moment;
}

const ShiftTimeStartLabel = ({ shiftActivity, rangeStart, rangeEnd }: ShiftTimeStartLabelProps) => {
    return (
        <div className={'display-flex'}>
            <div className={'width-200 padding-right-10'} />
            <div className={'width-100pct padding-left-20 margin-right-20'}>
                {shiftActivity ? (
                    <div
                        style={{
                            ...getStateStyle(shiftActivity, rangeStart, rangeEnd),
                            position: 'relative',
                            minWidth: '70px',
                        }}
                    >
                        <label className={'margin-bottom-0'}>
                            <FormattedMessage id={'intl-msg:timed.common.shiftBegin'} />
                        </label>
                        <br />
                        <FormattedTimeStamp date={shiftActivity.from} />
                    </div>
                ) : (
                    <div className={'padding-20'} />
                )}
            </div>
        </div>
    );
};

const ShiftTimeLabel = ({
    shiftTimeDuration,
    displayShiftTimeDuration,
}: {
    shiftTimeDuration: number | undefined;
    displayShiftTimeDuration: boolean;
}) => (
    <div className={'width-200 display-flex justify-content-end align-items-center'}>
        <div className={'display-inline-block'}>
            <FormattedMessage id={'intl-msg:timed.common.shiftTime'} />
        </div>
        <div className={'padding-left-10'}>
            {displayShiftTimeDuration ? (
                <span className={'badge bg-success'}>
                    <DurationComponent
                        minutes={shiftTimeDuration && shiftTimeDuration > 0 ? shiftTimeDuration : 0}
                        displayColor={'white'}
                        displaySize={'14'}
                    />
                    <sup>{'*'}</sup>
                </span>
            ) : (
                <EmptyDataIcon />
            )}
        </div>
    </div>
);

const getShiftTime = (
    historicalViewSelectionState: DetailsDisplayStateChange,
    rangeStart: Moment,
    rangeEnd: Moment
): JSX.Element => {
    const shiftStart = historicalViewSelectionState.shiftTimes?.shiftStart;
    const shiftEnd = historicalViewSelectionState.shiftTimes?.shiftEnd;
    const isShiftTimeKnown = shiftStart !== undefined && shiftEnd !== undefined;

    const normalisedShiftStart = shiftStart && shiftStart.isAfter(rangeStart) ? shiftStart : rangeStart;
    const normalisedShiftEnd = shiftEnd && shiftEnd.isBefore(rangeEnd) ? shiftEnd : rangeEnd;
    const calculatedShiftTimeMinutes = normalisedShiftEnd.diff(normalisedShiftStart, 'minutes');
    const shiftActivity = isShiftTimeKnown
        ? { from: normalisedShiftStart, to: normalisedShiftEnd, activity: Activities.WORKING }
        : undefined;

    return (
        <div
            className={'padding-top-5 padding-bottom-20 align-items-center text-size-14'}
            data-testid={'stateChangesDialog-shiftTimes'}
        >
            <ShiftTimeStartLabel shiftActivity={shiftActivity} rangeStart={rangeStart} rangeEnd={rangeEnd} />
            <div className={'display-flex align-items-center'}>
                <ShiftTimeLabel
                    shiftTimeDuration={calculatedShiftTimeMinutes}
                    displayShiftTimeDuration={isShiftTimeKnown}
                />
                <div className={'width-100pct padding-left-20'}>
                    {
                        <div className={'height-20 position-relative bg-lightest margin-right-20'}>
                            {shiftActivity ? (
                                <>
                                    <TimeBlock
                                        rangeStart={rangeStart}
                                        rangeEnd={rangeEnd}
                                        activity={shiftActivity}
                                        forcedColor={'bg-success'}
                                        showTooltip={false}
                                    />
                                    <div
                                        key={'state-change-shift-start-indicator'}
                                        className={'bg-success'}
                                        style={{
                                            ...getStateStyle(shiftActivity, rangeStart, rangeEnd),
                                            width: '1px',
                                            height: '5px',
                                            marginTop: '-5px',
                                        }}
                                    />
                                </>
                            ) : null}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

interface MetaDataInfoHeaderProps {
    historicalViewSelectionState: DetailsDisplayStateChange;
    receivedTimestamp: moment.Moment | undefined;
}

const MetaDataInfo = (props: MetaDataInfoHeaderProps) => {
    const { historicalViewSelectionState, receivedTimestamp } = props;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const activityConfig = ActivityConfig[historicalViewSelectionState.working_state.state];
    const statusDuration = moment(historicalViewSelectionState.state_change_end).diff(
        moment(historicalViewSelectionState.state_change_start),
        'minutes'
    );
    return (
        <div className={'display-flex justify-content-between form-group'}>
            <div>
                <label className={'margin-bottom-0'}>
                    <FormattedMessage id={'intl-msg:glossary.vehicle'} />
                </label>
                <div className={'text-size-large'}>{historicalViewSelectionState.vehicleName}</div>
            </div>
            <div>
                <label className={'margin-bottom-0'}>
                    <FormattedMessage id={'intl-msg:glossary.driver'} />
                </label>
                <div className={'text-size-large'}>{historicalViewSelectionState.driverName}</div>
            </div>
            <div>
                <label className={'margin-bottom-0'}>
                    <FormattedMessage id={'intl-msg:timed.common.timestamp.latest'} />
                </label>
                <div className={'text-size-large'}>
                    <InlineTimeStamp
                        value={
                            historicalViewSelectionState.state_change_end
                                ? moment(historicalViewSelectionState.state_change_end)
                                : undefined
                        }
                        receivedTimestamp={receivedTimestamp}
                    />
                </div>
            </div>
            <div className={'padding-right-25'}>
                <div className={'display-inline-block'}>
                    <div className={'display-flex'}>
                        <div>
                            <label className={'margin-bottom-0'}>
                                <FormattedMessage id={'intl-msg:timed.common.activity.latest'} />
                            </label>
                            <div>
                                <DurationComponent
                                    minutes={statusDuration ? statusDuration : 0}
                                    displaySize={'large'}
                                    displayColor={activityConfig ? activityConfig.color : INACTIVE_COLOR}
                                />
                            </div>
                        </div>
                        <div className={'text-size-12 display-flex align-self-start'}>
                            {activityConfig ? (
                                <span
                                    className={
                                        `rioglyph rioglyph-${activityConfig.uiString} ` +
                                        `text-color-white bg-${activityConfig.color} border-radius-100pct ` +
                                        'padding-5 text-size-200pct margin-left-10 margin-top-5 rounded-circle'
                                    }
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const getDetailsDialogBody = (
    historicalViewSelectionState: DetailsDisplayStateChange,
    receivedTimestamp: moment.Moment | undefined,
    rangeStart: Moment,
    rangeEnd: Moment
) => (
    <div data-testid={'stateChangesDialog-body'}>
        <MetaDataInfo
            historicalViewSelectionState={historicalViewSelectionState}
            receivedTimestamp={receivedTimestamp}
        />
        {
            <div className={'display-flex'}>
                <div className={'width-200'} />
                <div className={'width-100pct padding-20 padding-bottom-10 bg-lightest padding-top-2 text-light'}>
                    <TimelineComponent startRange={rangeStart} endRange={rangeEnd} />
                </div>
            </div>
        }
        {rangeStart.isBefore(moment().subtract(1, 'day').startOf('day'))
            ? null
            : getShiftTime(historicalViewSelectionState, rangeStart, rangeEnd)}

        <ActivityRow
            activities={historicalViewSelectionState.stateChanges}
            rangeStart={rangeStart}
            rangeEnd={rangeEnd}
        />
        <ActivityRow
            activityType={Activities.DRIVING}
            activities={historicalViewSelectionState.stateChanges}
            rangeStart={rangeStart}
            rangeEnd={rangeEnd}
        />
        <ActivityRow
            activityType={Activities.WORKING}
            activities={historicalViewSelectionState.stateChanges}
            rangeStart={rangeStart}
            rangeEnd={rangeEnd}
        />
        <ActivityRow
            activityType={Activities.RESTING}
            activities={historicalViewSelectionState.stateChanges}
            rangeStart={rangeStart}
            rangeEnd={rangeEnd}
        />
        <ActivityRow
            activityType={Activities.AVAILABLE}
            activities={historicalViewSelectionState.stateChanges}
            rangeStart={rangeStart}
            rangeEnd={rangeEnd}
        />
        <hr />
        <ActivityRow
            activities={historicalViewSelectionState.stateChanges}
            rangeStart={rangeStart}
            rangeEnd={rangeEnd}
            renderNegativeChart={true}
        />
    </div>
);

export const StateChangesDetailsDialog = (props: StateChangesDetailsDialogProps): JSX.Element | null => {
    const { receivedTimestamp, historicalViewSelectionState, onClose, startRange, endRange } = props;

    if (!historicalViewSelectionState) {
        return null;
    }

    const activitiesInTimeRange = historicalViewSelectionState.stateChanges
        .filter((it) => moment(it.state_change_start).isBefore(endRange))
        .filter((it) => moment(it.state_change_end).isAfter(startRange));

    const historicalViewSelectionStateInTimeRange = {
        ...historicalViewSelectionState,
        stateChanges: activitiesInTimeRange,
    };

    const earliestRoundToHourActivityStart = moment
        .min(activitiesInTimeRange.map((it) => moment(it.state_change_start)))
        .startOf('hour');

    const latestRoundToHourActivityEnd = moment
        .max(activitiesInTimeRange.map((it) => moment(it.state_change_end)))
        .add(1, 'hour')
        .startOf('hour');

    const normalisedStartRange = earliestRoundToHourActivityStart.isBefore(startRange)
        ? startRange
        : earliestRoundToHourActivityStart;
    const closestEndRange = latestRoundToHourActivityEnd.isAfter(endRange) ? endRange : latestRoundToHourActivityEnd;

    const normalisedEndRange = closestEndRange.isAfter(moment()) ? moment() : closestEndRange;
    return (
        <Dialog
            title={
                <span data-testid={'stateChangesDialog-title'}>
                    <FormattedMessage id={'intl-msg:timed.sidebar.detailViewFor'} />
                    {` ${historicalViewSelectionStateInTimeRange.driverName}`}
                    {': '}
                    {getTimeFrameText(normalisedStartRange, normalisedEndRange)}
                </span>
            }
            body={getDetailsDialogBody(
                historicalViewSelectionStateInTimeRange,
                receivedTimestamp,
                normalisedStartRange,
                normalisedEndRange
            )}
            footer={
                <div className={'display-flex text-left'}>
                    <label className={'padding-right-5'}>
                        <span>{'*'}</span>
                    </label>
                    <label>
                        <FormattedMessage id={'intl-msg:timed.dialog.timeSumInfoText'} />
                    </label>
                </div>
            }
            show={true}
            onClose={onClose}
            bsSize={'lg'}
        />
    );
};
