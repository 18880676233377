import { JSX } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { getIdsOfSelectedAssets } from '../../tree/selectionService';
import { isEmpty } from 'lodash';
import {
    createGoogleAnalyticsEvent,
    TrackingAction,
    TrackingCategory,
} from '../../../configuration/setup/googleTagManager';
import { WidgetVisibilityTrackerContainer } from '../../../configuration/setup/VisbilityTracker.container';
import { State } from '../../../reducers';

interface NoDataViewProps {
    selectedAsset?: string;
}

const LinkToSPA = ({ assetId }: { assetId: string }): JSX.Element => (
    <div className="border border-top-only text-center padding-10">
        <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://drivingtime.rio.cloud/#realtime?assetIds=${assetId}`}
            data-testid={'spa-link'}
        >
            <FormattedMessage id={'intl-msg:timed.widget.link.spa'} />
        </a>
    </div>
);

export const NoSignalView = (props: NoDataViewProps): JSX.Element | null => {
    const { selectedAsset } = props;
    if (!selectedAsset) {
        return null;
    }

    return (
        <div className="border rounded" data-testid={'no-signal-view'}>
            <WidgetVisibilityTrackerContainer
                googleAnalyticsEvent={createGoogleAnalyticsEvent(
                    TrackingCategory.WIDGET_VIEW,
                    TrackingAction.SHOW_NO_DATA_FOUND_FOR_ASSET
                )}
            />
            <div className="text-center padding-top-20 padding-bottom-20">
                <div className="text-size-16 text-color-dark">
                    <FormattedMessage id={'intl-msg:timed.widget.noSignal'} />
                </div>
            </div>
            <LinkToSPA assetId={selectedAsset} />
        </div>
    );
};

const getSelectedAsset = (state: State): string | undefined => {
    const idsOfSelectedAssets = getIdsOfSelectedAssets(state);
    if (isEmpty(idsOfSelectedAssets) || idsOfSelectedAssets.length > 1) {
        return undefined;
    }
    return idsOfSelectedAssets[0];
};

const mapStateToProps = (state: State): NoDataViewProps => ({
    selectedAsset: getSelectedAsset(state),
});

export const NoSignalViewContainer = connect(mapStateToProps)(NoSignalView);
