import { JSX } from 'react';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import { connect } from 'react-redux';
import { getTableViewMode } from '../../realtime/header/tablesettings/redux/tablesettings.redux';
import { TableViewMode } from '../../realtime/header/tablesettings/redux/types';
import { DrivingTimesTableBodyContainer } from './DrivingTimesTableBody.container';
import { DrivingTimesTableColGroupsContainer } from '../header/TableColGroups.container';
import { DrivingTimesTableHeadContainer } from './DrivingTimesTableHead.container';
import { LoadMoreDrivingTimesButtonContainer } from '../../realtime/header/tablesettings/LoadMoreButton.container';
import { State } from '../../../reducers';

interface DrivingTimeTableProps {
    viewType: TableViewMode;
}

const DrivingTimesTable = (props: DrivingTimeTableProps): JSX.Element => {
    const { viewType } = props;

    const tableClassNames = [
        'table',
        'table-sticky',
        'table-layout-fixed',
        'table-column-overflow-hidden',
        'table-bordered',
        'table-head-filled',
        viewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD && 'table-cards table-single-card',
        viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS && 'table-cards table-multi-cards',
    ].join(' ');

    return (
        <div data-testid={'DrivingTimesTable'}>
            <div>
                <table className={tableClassNames}>
                    <DrivingTimesTableColGroupsContainer />
                    <DrivingTimesTableHeadContainer />
                    <DrivingTimesTableBodyContainer />
                </table>
            </div>
            <LoadMoreDrivingTimesButtonContainer />
        </div>
    );
};

const mapStateToProps = (state: State): DrivingTimeTableProps => ({
    viewType: getTableViewMode(state),
});

export const DrivingTimesTableContainer = connect(mapStateToProps)(DrivingTimesTable);
