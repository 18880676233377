import { JSX, MouseEvent } from 'react';
import SortArrows from '@rio-cloud/rio-uikit/SortArrows';

import { TableHeadDispatchProps, TableHeadProps } from './types';
import { FormattedMessage } from 'react-intl';
import { HeaderIcon } from '../elements/HeaderIcon';
import { TableHeadCellStateChanges } from './TableHeadCellStateChanges';
import { calculateSortDir } from '../../realtime/header/tablesettings/sortDirService';
import { tableSettingsActions } from '../../realtime/header/tablesettings/redux/tablesettings.redux';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getEnhancedSortByAndDir } from '../sorting/sortingService';
import {
    createGoogleAnalyticsEvent,
    dataLayerPush,
    TrackingAction,
    TrackingCategory,
} from '../../../configuration/setup/googleTagManager';
import { State } from '../../../reducers';
import { getColumnHeaderIcon, getColumnHeaderMessageId } from './columnHeaderFormattingService';
import { TableColumn } from '../../enums/TableColumn';
import { SortDirectionType } from '@rio-cloud/rio-uikit/utils/SortUtils';

const TableHead = (props: TableHeadProps): JSX.Element => {
    const { columns, sortBy, sortDir, onSortChange } = props;

    const handleSortChange = (event: MouseEvent<HTMLElement>): void => {
        const newSortBy = event.currentTarget.getAttribute('data-sortby') as TableColumn;
        onSortChange(newSortBy, calculateSortDir(sortDir, newSortBy, sortBy));
        dataLayerPush(
            createGoogleAnalyticsEvent(
                TrackingCategory.ANY_TABLE,
                TrackingAction.CHANGE_TABLE_SORTING,
                `${newSortBy}_${sortDir}`
            )
        );
    };

    const getIconIfConfigured = (column: TableColumn): JSX.Element | null => {
        const columnIconElement = getColumnHeaderIcon(column);
        // TODO translated Tooltip?
        return columnIconElement ? (
            <span>
                <HeaderIcon type={columnIconElement} />
            </span>
        ) : null;
    };

    const getTableHeadCell = (column: TableColumn): JSX.Element => (
        <th
            key={column}
            className={'user-select-none sort-column ellipsis-1'}
            onClick={handleSortChange}
            data-field={column}
            data-sortby={column}
        >
            <span>
                {sortBy === column ? <SortArrows direction={sortDir} /> : <SortArrows />}
                <span>
                    {getIconIfConfigured(column)}
                    <FormattedMessage id={getColumnHeaderMessageId(column)} />
                </span>
            </span>
        </th>
    );

    return (
        <thead>
            <tr>
                {columns.map((column) =>
                    column === TableColumn.STATE_CHANGES ? (
                        <TableHeadCellStateChanges key={column} />
                    ) : (
                        getTableHeadCell(column)
                    )
                )}
            </tr>
        </thead>
    );
};

const mapStateToProps = (state: State): { sortBy: TableColumn; sortDir: SortDirectionType } => {
    const { sortDir, sortBy } = getEnhancedSortByAndDir(state);
    return {
        sortBy,
        sortDir,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): TableHeadDispatchProps => ({
    onSortChange: (sortBy: TableColumn, sortDir: SortDirectionType): void => {
        dispatch(tableSettingsActions.setSortBy(sortBy));
        dispatch(tableSettingsActions.setSortDir(sortDir));
    },
});

export const ConnectedTableHead = connect(mapStateToProps, mapDispatchToProps)(TableHead);
