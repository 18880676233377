import { JSX } from 'react';
import { DrivingTimesDisplayItem } from '../../tables/drivingtimes/types';
import { WorkingTimesCell } from './elements/WorkingTimesCell';
import { TableColumn } from '../../enums/TableColumn';

interface DrivingTimesProps {
    displayItem: DrivingTimesDisplayItem;
}

export const WorkingTimeDetailsView = (props: DrivingTimesProps): JSX.Element | null => {
    const { displayItem } = props;

    if (!displayItem) {
        return null;
    }

    return (
        <div className={'display-grid grid-cols-2 gap-20 margin-bottom-20'}>
            <div className={'display-flex flex-column border rounded'}>
                <WorkingTimesCell
                    testIdPrefix={'daily-working-time'}
                    currentMinutes={displayItem[TableColumn.CURRENT_DAILY_WORKING_TIME]?.duration}
                    remainingMinutes={displayItem[TableColumn.REMAINING_DAILY_WORKING_TIME]?.duration}
                    headerMessageId={'intl-msg:timed.driving-time-table.dailyWorkingTime'}
                />
            </div>
            <div className={'display-flex flex-column border rounded'}>
                <WorkingTimesCell
                    testIdPrefix={'weekly-working-time'}
                    currentMinutes={displayItem[TableColumn.CURRENT_WEEKLY_WORKING_TIME]?.duration}
                    remainingMinutes={displayItem[TableColumn.REMAINING_WEEKLY_WORKING_TIME]?.duration}
                    headerMessageId={'intl-msg:timed.driving-time-table.weeklyWorkingTime'}
                />
            </div>
        </div>
    );
};
